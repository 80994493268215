import { useEffect, useState } from 'react';
import { Card, CardContent, Container, Grid, Paper, Stack } from '@mui/material';
import SimCardIcon from '@mui/icons-material/SimCard';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import PolicyIcon from '@mui/icons-material/Policy';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

import classNames from 'classnames/bind';
import styles from './dashboard.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { checkTokenDashboad, getCookie, getLocalStorage, refreshTokenNew } from '../../utils/helper';
import { PeopleAlt, PublishedWithChanges } from '@mui/icons-material';
const cx = classNames.bind(styles);

function Dashboard() {
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  const [authDomain, setAuthDomain] = useState({});
  useEffect(() => {
    const token = getCookie('token');
    setAuthDomain(token);
    document.title = 'Tổng quan quản trị website ' + hostname;
  }, []);
  return (
    <Container sx={{ pb: '65px' }}>
      <Grid container spacing={1} justifyContent="center">
        {(authDomain?.permission === '1' ||
          authDomain?.permission === '2' ||
          authDomain?.permission === '3' ||
          authDomain?.permission === '6' ||
          authDomain?.permission === '7' ||
          authDomain?.permission === '4') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/admin">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <PermContactCalendarIcon color="info" />
                    <h3>Quản Lý Thông Tin (ADMIN)</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Thêm sửa xóa thông tin...</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {(authDomain?.permission === '1' ||
          authDomain?.permission === '2' ||
          authDomain?.permission === '3' ||
          authDomain?.permission === '6' ||
          authDomain?.permission === '7' ||
          authDomain?.permission === '4') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/giaodien">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <ViewQuiltIcon color="success" />
                    <h3>Quản Lý Giao Diện</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Thêm sửa xóa thông tin...</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}
        {/* {(authDomain?.permission === '2' || authDomain?.permission === '3' || authDomain?.permission === '5') && ( */}
        <Grid item xs={6} md={4} component={Link} to="/ctv">
          <Paper elevation={0}>
            <Card variant="outlined" sx={{ borderRadius: 2 }}>
              <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                  <SimCardIcon color="secondary" />
                  <h3>Quản Lý Bảng Sim Cá Nhân</h3>
                </Stack>
                {/* <Stack direction="column" spacing={0}>
                  <span>Copy 1-n sim,làm Ảnh, xuất Excel, Công Nợ....</span>
                </Stack> */}
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        {/* )} */}

        {(authDomain?.permission === '3' || authDomain?.permission === '7') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/dsctv">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <PeopleAlt color="info" />
                    <h3>Quản Lý Công Tác Viên</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Thêm sửa xóa thông tin...</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {(authDomain?.permission === '3' || authDomain?.permission === '7' || authDomain?.permission === '5') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/dsloadsim">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <PublishedWithChanges color="success" />
                    <h3>Quản Lý SIM Đang Load</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Thêm sửa xóa thông tin...</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {(authDomain?.permission === '3' || authDomain?.permission === '6') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/shops">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <ShoppingBagIcon color="error" />
                    <h3>Quản Lý Sản Phẩm</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Thêm sửa xóa thông tin...</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}
        {(authDomain?.permission === '1' ||
          authDomain?.permission === '2' ||
          authDomain?.permission === '3' ||
          authDomain?.permission === '6' ||
          authDomain?.permission === '7' ||
          authDomain?.permission === '4') && (
          <Grid item xs={6} md={4} component={Link} to="/photo">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <AddPhotoAlternateIcon color="success" />
                    <h3>Phần mềm làm NHIỀU ẢNH</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Chỉnh sửa ảnh, download ảnh 1 or nhiều 1 lúc</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}
        {authDomain?.permission !== '5' && (
          <Grid item xs={6} md={4} component={Link} to="/congno">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <EventNoteIcon color="secondary" />
                    <h3>Quản Lý Công Nợ (Thu - Chi)</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Công nợ sim, báo cáo, xuất excel công nợ...</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {(authDomain?.permission === '1' ||
          authDomain?.permission === '2' ||
          authDomain?.permission === '3' ||
          authDomain?.permission === '6' ||
          authDomain?.permission === '7' ||
          authDomain?.permission === '4') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/notifisim">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <NotificationsActiveIcon color="error" />
                    <h3>Quản Lý Thông Báo Sim</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Cập nhật hiển thị thông báo trên đầu trang sim</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}
        {(authDomain?.permission === '3' ||
          authDomain?.permission === '6' ||
          authDomain?.permission === '7' ||
          authDomain?.permission === '4') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/flashsale">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <OfflineBoltIcon color="error" />
                    <h3>Quản Lý Flash Sale (Khung Giờ)</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Cập nhật hiển thị Flash Sale theo khung giờ</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}
        {(authDomain?.permission === '1' ||
          authDomain?.permission === '2' ||
          authDomain?.permission === '3' ||
          authDomain?.permission === '6' ||
          authDomain?.permission === '7' ||
          authDomain?.permission === '4') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/news">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <ArticleRoundedIcon color="success" />
                    <h3>Quản Lý Bài Viết (Tin Tức)</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Cập nhật hiển thị bài viết</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}

        {/* {(authDomain?.permission === '3' || authDomain?.permission === '5') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/checksim">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <PolicyIcon color="warning" />
                    <h3>Kiểm Tra Sim Đại Lý</h3>
                  </Stack>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )} */}
        {/* {(authDomain?.permission === '3' || authDomain?.permission === '6' || authDomain?.permission === '4') && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/checktho">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <SavedSearchIcon color="warning" />
                    <h3>Kiểm Tra Sim Đại Lý</h3>
                  </Stack>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )} */}

        {authDomain?.permission === '3' && (
          <Grid item xs={6} md={4} component={Link} to="/dashboard/digishop">
            <Paper elevation={0}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }} className={cx('css-frame')}>
                  <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <CardGiftcardIcon color="action" />
                    <h3>Quản Lý Sim DiGiShop</h3>
                  </Stack>
                  {/* <Stack direction="column" spacing={0}>
                    <span>Thêm, sửa, xóa sim ...</span>
                  </Stack> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Dashboard;
