import {
  Button,
  Card,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { addCtv, listAllCtv, listCountAllCtv, listIdCtv, removeCtv, updateCtv } from '../../../services/apiUsersCtv';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCookie } from '../../../utils/helper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export default function DsUser() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#005663',
      color: theme.palette.common.white,
      borderRadius: 1,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const auth = getCookie('token');
  const hostname = window.location.hostname;
  const [resultList, setResultList] = useState([]);
  const [arrDefault, setArrDefault] = useState({ name: '', username: '', email: '', pass_word: '' });
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [page, setPage] = useState(1);
  const [countDefault, setCountDefault] = useState(0);
  const [checkInfo, setCheckInfo] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const idCtv = urlParams.get('u');
  const navigate = useNavigate();

  const listAll = async (pages = 1) => {
    const resultAPI = await listAllCtv(pages, 50, hostname);
    if (resultAPI?.length > 0) {
      setResultList(resultAPI);
      const count = await listCountAllCtv(hostname);
      setCountDefault(count.totalRows);
    }
  };

  useEffect(() => {
    document.title = 'Danh sách ctv';
    listAll(1);
  }, []);

  useEffect(() => {
    getListID(idCtv);
  }, [idCtv]);

  const getListID = async (id) => {
    if (id) {
      const resultID = await listIdCtv(id);
      if (resultID.length > 0) {
        setCheckInfo(true);
        setArrDefault(resultID[0]);
      } else {
        setCheckInfo(false);
      }
    }
  };

  const changeValueAdmin = (jsonVL) => {
    setArrDefault({ ...arrDefault, ...jsonVL });
  };

  const subAddDefault = async () => {
    setIsDisabled(true);
    const body = { ...arrDefault, permission: 5, link_domain: hostname, status: 1 };
    const resultAPI = await addCtv(body);
    if (resultAPI.message === 'OK') {
      setIsDisabled(false);
      listAll(1);
      setMessage('Thành Công');
      setOpenAlert(true);
      setArrDefault({ name: '', username: '', email: '', pass_word: '' });
    } else {
      setIsDisabled(false);
      setMessage('Thất Bại');
      setOpenAlert(true);
    }
  };
  const subUpdateDefault = async () => {
    setIsDisabled(true);
    const body = {
      id: idCtv,
      data: arrDefault,
    };
    const resultAPI = await updateCtv(body);
    if (resultAPI.message === 'OK') {
      setIsDisabled(false);
      listAll(1);
      navigate('/dashboard/dsctv');
      setMessage('Thành Công');
      setOpenAlert(true);
      setArrDefault({ name: '', username: '', email: '', pass_word: '' });
      setCheckInfo(false);
    } else {
      setIsDisabled(false);
      setMessage('Thất Bại');
      setOpenAlert(true);
    }
  };

  const alertRemove = async (id) => {
    if (window.confirm(`Bạn đã chắc chắn XÓA dữ liệu này?`)) {
      // Save it!
      const body = {
        id: id,
      };
      const resultAPI = await removeCtv(body);
      if (resultAPI?.message === 'OK') {
        listAll(1);
      }
    } else {
      // Do nothing!
      console.log('Hủy yêu cầu');
    }
  };

  const nextPage = () => {
    const total = Math.ceil(countDefault / 50);
    if (total > page) {
      setPage(page + 1);
      listAll(page + 1);
    }
  };

  const prevPage = () => {
    const total = Math.ceil(countDefault / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listAll(page - 1);
    }
  };

  return (
    <Grid container sx={{ pb: '90px', background: '#fff', p: 2 }} spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          value={arrDefault?.name}
          label="Họ và Tên"
          placeholder="Nhập Họ và Tên"
          variant="outlined"
          onChange={(e) => {
            changeValueAdmin({ name: e.target.value });
          }}
          size="small"
          fullWidth
        />
        <TextField
          value={arrDefault?.username}
          label="Tên đăng nhập ( viết liền không dấu)"
          placeholder="Nhập tên đăng nhập"
          variant="outlined"
          onChange={(e) => {
            changeValueAdmin({ username: e.target.value });
          }}
          size="small"
          fullWidth
          sx={{ mt: 1 }}
        />
        <TextField
          value={arrDefault?.pass_word}
          label="Mật khẩu"
          placeholder="Nhập mật khẩu"
          variant="outlined"
          onChange={(e) => {
            changeValueAdmin({ pass_word: e.target.value });
          }}
          size="small"
          fullWidth
          sx={{ mt: 1 }}
        />
        <TextField
          value={arrDefault?.email}
          label="Email (nếu có)"
          placeholder="Nhập email"
          variant="outlined"
          onChange={(e) => {
            changeValueAdmin({ email: e.target.value });
          }}
          size="small"
          fullWidth
          sx={{ mt: 1 }}
        />
        <Button
          size="small"
          variant="contained"
          onClick={checkInfo ? subUpdateDefault : subAddDefault}
          disabled={isDisabled}
          sx={{ mt: 1 }}
        >
          {checkInfo ? 'CẬP NHẬT' : 'THÊM MỚI'}
        </Button>
      </Grid>

      <Grid item md={6} xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Họ Tên</StyledTableCell>
                <StyledTableCell align="center">Tài khoản</StyledTableCell>
                <StyledTableCell align="center">Tác Vụ</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultList?.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.username}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      onClick={() => {
                        getListID(row.id);
                        navigate(`/dashboard/dsctv?u=${row.id}`);
                      }}
                      sx={{ color: arrDefault.id === row.id ? `red` : `` }}
                    >
                      Sửa
                    </Button>
                    <Button onClick={() => alertRemove(row.id)}>Xóa</Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
