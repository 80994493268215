import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames/bind';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import GradeIcon from '@mui/icons-material/Grade';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import SimCardRoundedIcon from '@mui/icons-material/SimCardRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import TaskIcon from '@mui/icons-material/Task';

import SimCardIcon from '@mui/icons-material/SimCard';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import LoyaltyTwoToneIcon from '@mui/icons-material/LoyaltyTwoTone';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoopIcon from '@mui/icons-material/Loop';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShareIcon from '@mui/icons-material/Share';

import { ThemeContext } from '../../../component/ThemeContext/ThemeContext';
import { ButtonUI, FrameInfoSim } from '../../../component/CustomerMUI';
import styles from '../Home.module.scss';
import images from '../../../assets/images';
import ThemeLoad from '../../../component/CustomerMUI/ThemeLoad/ThemeLoad';
import { DataSim } from '../../../utils/DataSim';
import { bgrCateSim } from '../../../utils/bgrCateSim';
import {
  numberFormatText,
  numberFormatDeleteDots,
  totalDiscount,
  numberFormat,
  setLocalStorage,
  getLocalStorage,
  removeSpecialCharacters,
  checkTokenDashboad,
  totalPriceDiscount,
  numberFormatTextShorten,
  getCookie,
} from '../../../utils/helper';
import {
  Alert,
  AppBar,
  Avatar,
  Backdrop,
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  Hidden,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { LogicCategoryShowName } from '../LogicSearch/LogicCategoryShowName';
import * as listSimService from '../../../services/listSim';
import * as userIDService from '../../../services/apiUsers';
import { Box, fontSize } from '@mui/system';
import FlashSale from '../FlashSale';
import ImageExcel from '../ImageExcel';
import { API_URL_SIMSO } from '../../../config/config';
import Discount from './Discount';
import { getDiscount } from '../../../services/apiDiscount';
import ExportExcel from './exportExcel';
import ExportExcelToCate from './exportExcelToCate';
import Debt from './Debt';
import { arrayFormatCate } from '../../../utils/formatSim';
import Photo from '../../Dashboard/Photo';
import styled from '@emotion/styled';
import { addKeepSimSo, listAllKeepSim, updateKeepSim } from '../../../services/apiUsersCtv';
import { isError } from 'lodash-es';

const cx = classNames.bind(styles);

const homeNetWork = [
  {
    titleNetWork: 'Mobifone',
    srcNetwork: images.logo_mb,
    width: '60px',
  },
  {
    titleNetWork: 'Viettel',
    srcNetwork: images.logo_vt,
    width: '60px',
  },
  {
    titleNetWork: 'Vinaphone',
    srcNetwork: images.logo_vn,
    width: '60px',
  },
  {
    titleNetWork: 'Itelecom',
    srcNetwork: images.logo_itel,
    width: '40px',
  },
  {
    titleNetWork: 'Vietnamobile',
    srcNetwork: images.logo_vnmb,
    width: '60px',
  },
  {
    titleNetWork: 'Wintel',
    srcNetwork: images.logo_wintel,
    width: '60px',
  },
];

const priceRange = [
  {
    id: 2,
    titlePrice: 'Dưới 1 triệu',
    value: '0,0.999',
  },
  {
    id: 3,
    titlePrice: '1 - 2 triệu',
    value: '1,2',
  },
  {
    id: 4,
    titlePrice: '2 - 3 triệu',
    value: '2,3',
  },
  {
    id: 5,
    titlePrice: '3 - 5 triệu',
    value: '3,5',
  },
  {
    id: 6,
    titlePrice: '5 - 10 triệu',
    value: '5,10',
  },
  {
    id: 7,
    titlePrice: '10 - 20 triệu',
    value: '10,20',
  },
  {
    id: 8,
    titlePrice: '20 - 50 triệu',
    value: '20,50',
  },
  {
    id: 9,
    titlePrice: '50 - 100 triệu',
    value: '50,100',
  },
  {
    id: 10,
    titlePrice: 'Trên 100 triệu',
    value: '100',
  },
];
const searchDauSoSim = [
  {
    dauso: '09*',
    titleDauSo: '09',
  },
  {
    dauso: '08*',
    titleDauSo: '08',
  },
  {
    dauso: '03*',
    titleDauSo: '03',
  },
  {
    dauso: '07*',
    titleDauSo: '07',
  },
  {
    dauso: '05*',
    titleDauSo: '05',
  },
];

const arrayMenhSim = [
  {
    title: 'Mệnh Mộc',
    link: 'moc',
  },
  {
    title: 'Mệnh Thủy',
    link: 'thuy',
  },
  {
    title: 'Mệnh Hỏa',
    link: 'hoa',
  },
  {
    title: 'Mệnh Thổ',
    link: 'tho',
  },
  {
    title: 'Mệnh Kim',
    link: 'kim',
  },
];

const tablePriceGDH = [
  {
    priceStart: 0,
    priceEnd: 2499999,
    cafe: 200000,
  },
  {
    priceStart: 2500000,
    priceEnd: 3999999,
    cafe: 250000,
  },
  {
    priceStart: 4000000,
    priceEnd: 6999999,
    cafe: 300000,
  },
  {
    priceStart: 7000000,
    priceEnd: 9999999,
    cafe: 350000,
  },
  {
    priceStart: 10000000,
    priceEnd: 19999999,
    cafe: 500000,
  },
  {
    priceStart: 20000000,
    priceEnd: 39999999,
    cafe: 800000,
  },
  {
    priceStart: 40000000,
    priceEnd: 100000000,
    cafe: 1000000,
  },
];

const soluongText = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
const soluongTextPhoi = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];
const columnText = [1, 2, 3, 4, 5];
const colorTextImage = [
  { name: 'red', color: '#ef0000' },
  { name: 'blue', color: '#0600ef' },
  { name: 'green', color: '#016066' },
  { name: 'black', color: '#000f10' },
  { name: 'origin', color: '#ff8400' },
];

const searchTranhso = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const favourite = (number) => {
  const last4Str = String(number).slice(-4);
  const last4Num = parseInt(last4Str);
  const tailnumbers = last4Num / 80;
  const surplus = getDecimalPart(tailnumbers);
  const totalHungCat = '0.' + surplus;
  const total = Math.round(parseFloat(totalHungCat) * 80);
  if (DataSim[total]['mucdo'] === 'Đại Cát' || DataSim[total]['mucdo'] === 'Cát') {
    return DataSim[total];
  } else {
    return '';
  }
};

const getDecimalPart = (num) => {
  // console.log(Number.isInteger(num), num);
  if (Number.isInteger(num)) {
    return 0;
  }

  const decimalStr = num.toString().split('.')[1];
  return parseInt(decimalStr);
};
function HomeCtv() {
  const elementRef = useRef();
  const navigate = useNavigate();
  const hostname = window.location.hostname;
  const getToken = getCookie('token');
  const { menhPs, catePs, mangPs, rangePs, search, dangsimPs, tranhso, istranhso, thuebaoPs } = useParams();

  const [simInfinteResult, setSimInfinteResult] = useState({ data: [] });
  const [simInfinteResultCount, setSimInfinteResultCount] = useState({ totalRows: 0 });
  const [menuSimResult, setMenuSimResult] = useState([]);
  const [page, setPage] = useState(1);
  const [loadinglist, setLoadingList] = useState(true);
  const [loadingTheme, setLoadingTheme] = useState(true);
  const [loadingTotalSim, setLoadingTotalSim] = useState('Chọn Số Đẹp');
  const [actionBtnSort, setActionBtnSort] = useState('');
  const [actionRangePriceSim, setActionRangePriceSim] = useState('');
  const [actionRangePrice, setActionRangePrice] = useState({ startPrice: '', endPrice: '' });
  const [actionNetWork, setActionNetWork] = useState('');
  const [actionMenuCate, setActionMenuCate] = useState(0);
  const [actionFormatSim, setActionFormatSim] = useState(0);
  const [actionMenhSim, setActionMenhSim] = useState(0);
  const [searchDauSim, setSearchDauSim] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [textSearchChange, setTextSearchChange] = useState('');
  const [actionTranhso, setActionTranhso] = useState([]);
  const [isSttTranhso, setIsSttTranhso] = useState(1); // 1 - tranh 6 số
  const [actionSimStart, setActionSimStart] = useState({ sodau: '', totalSoDau: '' });
  const [actionSimEnd, setActionSimEnd] = useState({ socuoi: '', totalSoCuoi: '' });
  const [isFocused, setIsFocused] = useState(false);
  const contextApiUser = useContext(ThemeContext);
  const loader = useRef(null);

  const [open, setOpen] = useState(false);
  const [openDiscount, setOpenDiscount] = useState(false);
  const [openRangePrice, setOpenRangePrice] = useState(false);
  const [pos, setPos] = useState(false);
  const [checkCoppy, setCheckCoppy] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [textCopied, setTextCopied] = useState('');
  const [textCopiedSim, setTextCopiedSim] = useState('');
  const [textCopiedSimPhoi, setTextCopiedSimPhoi] = useState('');
  const [textCopiedSimPhoiPrice, setTextCopiedSimPhoiPrice] = useState('');
  const [textCopiedSimAndPrice, setTextCopiedSimAndPrice] = useState('');
  const [textCopiedChot, setTextCopiedChot] = useState('');
  const [layoutSim, setLayoutSim] = useState('2');
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenDrawerCate, setIsOpenDrawerCate] = useState({ isCate: false, cateNumber: 0 });
  const [isCopyLink, setIsCopyLink] = useState('');
  const [iconCopy, setIconCopy] = useState('🍂');
  const [isShowCopyLink, setIsShowCopyLink] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [authDomain, setAuthDomain] = useState({});
  const [isSimDaily, setIsSimDaily] = useState(false);
  const [bgrDF, setBgrDF] = useState({ bgr: '', deg: '' });
  const [scroll, setScroll] = useState(0);
  const [isScroll, setIsScroll] = useState(true);
  const [textCopiedSimAndPriceGiaThu, setTextCopiedSimAndPriceGiaThu] = useState('');
  const [textCopiedSimAndPriceGiaThuKoTach, setTextCopiedSimAndPriceGiaThuKoTach] = useState('');
  const [textCopiedSimAndPriceGiaThuKhach, setTextCopiedSimAndPriceGiaThuKhach] = useState('');
  const [isloadingCoppyAll, setIsloadingCoppyAll] = useState(false);
  const [dataImageExcel, setDataImageExcel] = useState([]);
  const [isCheckInFoIMG, setIsCheckInFoIMG] = useState({
    soluong: 45,
    color: 'blue',
    column: 3,
    isPrice: false,
    title: '',
    title_footer: '',
    title_cols_a: 'SỐ ĐẸP',
    title_cols_b: 'GIÁ',
  });
  const [openImage, setOpenImage] = useState(false);
  const [openImagePhoi, setOpenImagePhoi] = useState(false);
  const [isCallImage, setIsCallImage] = useState(false);
  const [arrImageDown, setArrImageDown] = useState([]);
  const [imageDemo, setImageDemo] = useState('');
  const [isShowGDH, setIsShowGDH] = useState(false);
  const [resultDiscount, setResultDiscount] = useState([]);
  const [thuebao, setThuebao] = useState('');
  const [openSnac, setOpenSnac] = useState(false);
  const [errorSnac, setErrorSnac] = useState({ isError: false, message: '' });

  // const queryString = window.location.hash.replace(/^#\/home\?/, '');
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const mang = urlParams.get('mang');
  const text = urlParams.get('q');
  const loai = urlParams.get('loai');
  const dd = urlParams.get('dd');
  const range = urlParams.get('range');
  const order = urlParams.get('order');
  const menh = urlParams.get('menh');
  const ts = urlParams.get('ts');
  const ists = urlParams.get('ists');
  const tb = urlParams.get('tb');
  // console.log(useParams());

  const fetchApiUserID = async () => {
    const resultAPISIM = getCookie('token');
    if (resultAPISIM?.link_domain === hostname) {
      setAuthDomain(resultAPISIM);
      if (resultAPISIM.background) {
        setBgrDF({
          bgr: resultAPISIM['background']?.split(',')[0],
          deg: resultAPISIM['background']?.split(',')[1],
        });
        document.documentElement.style.setProperty('--home-color', resultAPISIM['background']?.split(',')[0]);
        document.documentElement.style.setProperty('--home-color-deg', resultAPISIM['background']?.split(',')[1]);
        document.body.style.backgroundColor = resultAPISIM['background']?.split(',')[0] + '0f';
      }
    } else {
      navigate('/user/login', { replace: true });
    }
  };
  const fetchApiSim = async () => {
    if (!isSimDaily) {
      setLoadingList(true);
      const resultAPISIM = await listSimService.listSimAll(
        hostname,
        page,
        textSearch.length === 0 || textSearch.includes('*') ? textSearch : `*${textSearch}`,
        actionNetWork,
        actionMenuCate,
        actionFormatSim,
        actionMenhSim,
        actionBtnSort,
        actionRangePriceSim,
        isSttTranhso,
        actionTranhso.join(''),
      );
      if (resultAPISIM?.datauser?.length > 0) {
        fetchApiUserID(resultAPISIM?.datauser);
      }
      if (resultAPISIM?.datasim?.data?.length > 0) {
        setLoadingTheme(false);
        setLoadingList(false);
        setIsSimDaily(false);
        let arrResult = simInfinteResult?.data;
        setSimInfinteResult({
          data: arrResult.concat(resultAPISIM?.datasim?.data),
        });
      } else {
        if (textSearch && authDomain?.is_simdaily > 0) {
          searchSimDaily(textSearch, actionNetWork, '', actionRangePriceSim, actionBtnSort, simInfinteResult, page);
        } else {
          setLoadingTheme(false);
          setLoadingList(false);
        }
      }
    } else {
      if (textSearch && getLocalStorage('khoso')?.is_simdaily > 0) {
        searchSimDaily(textSearch, actionNetWork, '', actionRangePriceSim, actionBtnSort, simInfinteResult, page);
      } else {
        setLoadingTheme(false);
        setLoadingList(false);
      }
    }
  };

  const fetchApiCateSim = async () => {
    const resultAPISIM = await listSimService.listCateSimAll(hostname);
    if (resultAPISIM['data'].length > 0) {
      setMenuSimResult(resultAPISIM.data);
    } else {
      setLoadingTheme(false);
      setLoadingList(false);
    }
  };
  // làm mới dữ liệu
  const fetchApiSimNew = async () => {
    setLoadingTheme(true);
    setLoadingList(true);
    const resultAPISIM = await listSimService.listDataSimID(hostname);
    if (resultAPISIM['message'] === 'Ok') {
      fetchApiSim();
    } else {
      setLoadingTheme(false);
      setLoadingList(false);
    }
  };

  //API LỌC SIM THEO ĐIỀU KIỆN
  const fetchApiLocSim = async (
    textsim = '',
    nhamang = '',
    theloai = '',
    dinhdang = '',
    menhsim = '',
    orderby = '',
    rangePrice = '',
    TranhsoSim = '',
    thuebaoSim = '',
  ) => {
    checkLinkLocSim(textsim, nhamang, theloai, dinhdang, menhsim, orderby, rangePrice, TranhsoSim, thuebaoSim);
    setLoadingTheme(true);
    setLoadingList(true);
    setIsSimDaily(false);
    setSimInfinteResult({ data: [] });
    setSimInfinteResultCount({ totalRows: 0 });
    setPage(1);

    const resultAPISIM = await listSimService.listSimAll(
      hostname,
      1,
      textsim.length === 0 || textsim.includes('*') ? textsim : `*${textsim}`,
      nhamang,
      theloai,
      dinhdang,
      menhsim,
      orderby,
      rangePrice,
      isSttTranhso,
      TranhsoSim,
      thuebaoSim,
    );
    if (resultAPISIM?.datauser?.length > 0) {
      fetchApiUserID(resultAPISIM?.datauser);
    }
    if (resultAPISIM?.datasim?.data?.length > 0) {
      setLoadingTheme(false);
      setLoadingList(false);
      setLoadingTotalSim('Đang tải số lượng sim...');
      let arrResult = [];
      setSimInfinteResult({
        data: arrResult.concat(resultAPISIM?.datasim?.data),
      });
      fetchApiCountLocSim(textsim, nhamang, theloai, dinhdang, menhsim, rangePrice, TranhsoSim, thuebaoSim);
    } else {
      if (textsim && getLocalStorage('khoso')?.is_simdaily > 0) {
        await searchSimDaily(textsim, nhamang, '', rangePrice, orderby, [], 1);
      } else {
        setLoadingTheme(false);
        setLoadingList(false);
      }
    }
  };

  const fetchApiCountLocSim = async (
    textsim = '',
    nhamang = '',
    theloai = '',
    dinhdang = '',
    menhsim = '',
    rangePrice = '',
    TranhsoSim = '',
    thuebaoSim = '',
  ) => {
    const resultAPISIMTOTAL = await listSimService.listSimAllCount(
      hostname,
      textsim.length === 0 || textsim.includes('*') ? textsim : `*${textsim}`,
      nhamang,
      theloai,
      dinhdang,
      menhsim,
      rangePrice,
      isSttTranhso,
      TranhsoSim,
      thuebaoSim,
    );
    setLoadingTotalSim('');
    setIsloadingCoppyAll(false);
    setSimInfinteResultCount({
      totalRows: resultAPISIMTOTAL,
    });
  };

  //tìm sim theo daily
  const searchSimDaily = async (keyword, nhamang = '', dauso = '', range = '', sapxep = '', dataSim, page) => {
    setLoadingList(true);
    let orderBySim = sapxep === 'ASC' ? 1 : sapxep === 'DESC' ? 2 : '';
    let nhamangdaily = '';
    switch (nhamang) {
      case 'Viettel':
        nhamangdaily = 1;
        break;
      case 'Vinaphone':
        nhamangdaily = 2;
        break;
      case 'Mobifone':
        nhamangdaily = 3;
        break;
      case 'Vietnamobile':
        nhamangdaily = 4;
        break;
      case 'Itelecom':
        nhamangdaily = 6;
        break;
      case 'Wintel':
        nhamangdaily = 7;
        break;

      default:
        nhamangdaily = '';
        break;
    }
    const resultAPISIM =
      removeSpecialCharacters(keyword).length === 10
        ? await listSimService.SimDailyDetail(keyword)
        : await listSimService.listSimDailyAll(keyword, nhamangdaily, dauso, range, orderBySim, page);
    if (resultAPISIM['data'].length > 0) {
      setLoadingTotalSim('');
      setLoadingTheme(false);
      setLoadingList(false);
      setIsSimDaily(true);
      let arrResult = dataSim && dataSim?.data?.length > 0 ? dataSim?.data : [];
      setSimInfinteResult({
        data: arrResult.concat(resultAPISIM.data),
      });
      setSimInfinteResultCount({
        totalRows: removeSpecialCharacters(keyword).length === 10 ? 1 : 1000,
      });
    } else {
      setLoadingTheme(false);
      setLoadingList(false);
    }
  };

  // hiển thị link đường dẫn
  const checkLinkLocSim = (
    textsim,
    nhamang,
    theloai,
    dinhdang,
    menhsim,
    orderby,
    rangePrice,
    TranhsoSim,
    thuebaoSim,
  ) => {
    const textsimValue = (textsim && `&q=${textsim}`) || '';
    const nhamangValue = (nhamang && `&mang=${nhamang}`) || '';
    const theloaiValue = (theloai && `&loai=${theloai}`) || '';
    const dinhdangValue = (dinhdang && `&dd=${dinhdang}`) || '';
    const menhsimValue = (menhsim && `&menh=${menhsim}`) || '';
    const rangePriceValue = (rangePrice && `&range=${rangePrice}`) || '';
    const thuebaoValue = (thuebaoSim && `&tb=${thuebaoSim}`) || '';
    const orderbyValue = `?order=${orderby === 'ASC' ? 1 : orderby === 'DESC' ? 2 : ''}`;
    const tranhsoValue = (TranhsoSim.length > 0 && `&ists=${isSttTranhso}&ts=${TranhsoSim}`) || '';
    navigate(
      `/ctv${orderbyValue}${textsimValue}${nhamangValue}${theloaiValue}${dinhdangValue}${menhsimValue}${rangePriceValue}${tranhsoValue}${thuebaoValue}`,
      { replace: true },
    );
  };

  useEffect(() => {
    if (page !== 1 && Math.ceil(simInfinteResultCount?.totalRows / 50) >= page) {
      setLoadingList(true);
      fetchApiSim();
    }
  }, [page]);

  const getParams = () => {
    let fmang = '';
    let ftext = '';
    let frange = '';
    let forder = '';
    let fmenh = '';
    let fcate = '';
    let fdinhdang = '';
    let fts = '';
    let ftb = '';

    if (mang || mangPs) {
      setActionNetWork(mang || mangPs);
      fmang = mang || mangPs;
    }
    if (text || search) {
      setTextSearch(text || search);
      setTextSearchChange(text || search);
      ftext = text || search;
    }
    if (loai || catePs) {
      setActionMenuCate(loai || catePs);
      fcate = loai || catePs;
    }
    if (dd || dangsimPs) {
      setActionFormatSim(dd || dangsimPs);
      fdinhdang = dd || dangsimPs;
    }
    if (tb || thuebaoPs) {
      setThuebao(tb || thuebaoPs);
      ftb = tb || thuebaoPs;
    }
    if (range || rangePs) {
      setActionRangePriceSim(range || rangePs);
      frange = range || rangePs;
    }
    if (order) {
      setActionBtnSort(order === '1' ? 'ASC' : order === 1 ? 'DESC' : '');
      forder = order === '1' ? 'ASC' : order === 1 ? 'DESC' : '';
    }
    if (menh || menhPs) {
      setActionMenhSim(menh || menhPs);
      fmenh = menh || menhPs;
    }
    if (ts || tranhso) {
      setIsSttTranhso(ists || istranhso || 1);
      fts = ts || tranhso;
      setActionTranhso(fts.toString().split('').map(Number));
    }

    fetchApiLocSim(ftext, fmang, fcate, fdinhdang, fmenh, forder, frange, fts, ftb);
  };

  const checkTimeNowUpdateSim = async () => {
    const data = await listAllKeepSim(1, 1000, hostname);
    if (data?.length > 0) {
      const today = new Date();

      // Extract day, month, and year
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();

      // Add leading zero to day and month if needed
      day = day < 10 ? '0' + day : day;
      month = month < 10 ? '0' + month : month;

      // Format the date as dd/mm/yyyy
      const formattedDate = `${day}/${month}/${year}`;

      const time =
        formattedDate +
        'T' +
        new Date().toLocaleTimeString('vi-VN', {
          timeZone: 'Asia/Ho_Chi_Minh',
        });

      data.map(async (row) => {
        const givenTimeString = row.timeend;
        const parseDateString = (dateString) => {
          const [datePart, timePart] = dateString.split('T');
          const [day, month, year] = datePart.split('/').map(Number);
          const [hours, minutes, seconds] = timePart.split(':').map(Number);
          return new Date(year, month - 1, day, hours, minutes, seconds);
        };

        const givenTime = parseDateString(givenTimeString);
        if (today.getTime() > givenTime.getTime()) {
          const simId = await listSimService.checkSimId(hostname, row.sim);
          // Save it!
          if (simId.data.length > 0) {
            const bodysim = {
              id: simId.data[0].id,
              data: { status: 1 },
            };
            await listSimService.updateSim(bodysim);
            //
            const body = {
              id: row.id,
              data: { status: 2 },
            };
            await updateKeepSim(body);
          }
        }
      });
    }
  };

  const fetchApiDiscount = async () => {
    let resultAPISIM = await getDiscount(hostname);
    if (resultAPISIM?.length > 0) {
      setResultDiscount(resultAPISIM);
    } else {
      setResultDiscount([]);
      setOpenDiscount(true);
    }
  };

  useEffect(() => {
    // fetchApiSim();
    fetchApiUserID();
    fetchApiCateSim();
    fetchApiDiscount();
    getParams();
    checkTimeNowUpdateSim();
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) observer.observe(loader.current);

    return () => observer.disconnect();
  }, [simInfinteResult, simInfinteResultCount]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && Math.ceil(simInfinteResultCount?.totalRows / 50) >= page) {
      setPage((page) => page + 1);
    }
  };

  // sort data network
  useEffect(() => {
    handleTop();
    setCheckCoppy([]);
    setDataImageExcel([]);
    if (textSearch?.length === 0) {
      setSearchDauSim('');
    }
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      if (window.scrollY > 300) {
        if (!pos) setPos(true);
      } else {
        if (pos) setPos(false);
      }

      if (isFocused) {
        setIsScroll(true);
      } else {
        if (window.scrollY > scroll) {
          setScroll(window.scrollY);
          setIsScroll(false);
          setIsFocused(false);
        } else {
          setIsFocused(false);
          setIsScroll(true);
        }
      }
    };

    // setLoadingList(false);
    // window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
    // Remove the event listener
    // return () => {
    //   elementRef.current.removeEventListener('scroll', handleScroll);
    // };
  }, [isFocused, scroll, page, simInfinteResult]);

  const handleClearSim = () => {
    setActionMenuCate(0);
    setActionNetWork('');
    setActionRangePrice({});
    setActionTranhso([]);
    setActionSimStart({ sodau: '', totalSoDau: '' });
    setActionSimEnd({ socuoi: '', totalSoCuoi: '' });
    setSearchDauSim('');
    setTextSearch('');
    setTextSearchChange('');
  };

  const handleChangeSelectDauSo = (event) => {
    setSearchDauSim(event.target.value);
    setTextSearch(event.target.value);
    setTextSearchChange(event.target.value);
    fetchApiLocSim(
      event.target.value,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      actionRangePriceSim,
      actionTranhso.join(''),
      thuebao,
    );
  };
  const handleChangeSelectDauSoDrawer = (value) => {
    setSearchDauSim(value);
    setTextSearch(value);
    setTextSearchChange(value);
    fetchApiLocSim(
      value,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      actionRangePriceSim,
      actionTranhso.join(''),
      thuebao,
    );
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRangePrice(false);
    // setTextSearch('');
    // setTextSearchChange('');
    setOpenShare(false);
    setOpenImage(false);
    setOpenImagePhoi(false);
    setOpenDiscount(false);
    setArrImageDown([]);
    // contextSearch.toggleDataSim('');
  };

  // handle scroll to top
  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setPos(false);
  };

  const handleCoppyIcon = (e) => {
    handleTextCopiedSimAndPrice(checkCoppy, e.target.value);
    handleTextCopiedSim(checkCoppy, e.target.value);
    handleTextCopiedSimAndPriceGiaThu(checkCoppy, e.target.value);
    handleTextCopiedSimAndPriceGiaThuKoTach(checkCoppy, e.target.value);
    handleTextCopiedSimAndPriceGiaThuKhach(checkCoppy, e.target.value);
  };

  // hanlde check coppy
  const handleCoppyAll = async (e) => {
    setIsScroll(true);
    setIsloadingCoppyAll(true);
    let arrsim = {};
    let arrData = [];
    if (e.target.checked) {
      if (isSimDaily || simInfinteResultCount.totalRows <= 50) {
        simInfinteResult?.data?.map((sim, index) => {
          arrsim[index] = sim;
          // arrData.push({ sim: sim.sim_show, price: numberFormatText(sim.price) });
        });
        setDataImageExcel(Object.values(arrsim));
        setCheckCoppy(arrsim);
        handleTextCopiedSimAndPrice(arrsim);
        handleTextCopiedSimAndPriceGiaThu(arrsim);
        handleTextCopiedSimAndPriceGiaThuKoTach(arrsim);
        handleTextCopiedSimAndPriceGiaThuKhach(arrsim);
        handleTextCopied(arrsim);
        handleTextCopiedSim(arrsim);
        setIsloadingCoppyAll(false);
      } else {
        const resultAPISIM = await listSimService.listSimAllSize(
          hostname,
          simInfinteResultCount.totalRows,
          textSearch.length === 0 || textSearch.includes('*') ? textSearch : `*${textSearch}`,
          actionNetWork,
          actionMenuCate,
          actionFormatSim,
          actionMenhSim,
          actionBtnSort,
          actionRangePriceSim,
          isSttTranhso,
          actionTranhso.join(''),
        );
        if (resultAPISIM?.datasim?.data.length > 0) {
          resultAPISIM?.datasim?.data?.map((sim, index) => {
            arrsim[index] = sim;
            // arrData.push({ sim: sim.sim_show, price: numberFormatText(sim.price) });
          });
          setDataImageExcel(Object.values(arrsim));
          setCheckCoppy(arrsim);
          handleTextCopiedSimAndPrice(arrsim);
          handleTextCopiedSimAndPriceGiaThu(arrsim);
          handleTextCopiedSimAndPriceGiaThuKoTach(arrsim);
          handleTextCopiedSimAndPriceGiaThuKhach(arrsim);
          handleTextCopied(arrsim);
          handleTextCopiedSim(arrsim);
          setIsloadingCoppyAll(false);
        }
      }
    } else {
      setCheckCoppy({});
      setDataImageExcel([]);
      setIsloadingCoppyAll(false);
    }
  };

  const handleCoppy = (index, sim) => {
    let state = { ...checkCoppy, [index]: sim };
    // let arrData = [...dataImageExcel, { sim: sim.sim_show, price: numberFormatText(sim.price) }];
    setDataImageExcel(Object.values(state));
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopiedSimAndPriceGiaThu(state);
    handleTextCopiedSimAndPriceGiaThuKoTach(state);
    handleTextCopiedSimAndPriceGiaThuKhach(state);
    handleTextCopied(state);
    handleTextCopiedSim(state);
    setIsScroll(true);
  };
  const handleDeleteCoppy = (index) => {
    let state = { ...checkCoppy };
    delete state[index];
    setDataImageExcel(Object.values(state));
    setCheckCoppy(state);
    handleTextCopiedSimAndPrice(state);
    handleTextCopiedSimAndPriceGiaThu(state);
    handleTextCopiedSimAndPriceGiaThuKoTach(state);
    handleTextCopiedSimAndPriceGiaThuKhach(state);
    handleTextCopied(state);
    handleTextCopiedSim(state);
    setIsScroll(true);
  };
  const handleTextCopiedSim = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim_show}`;
      return SimsCopied;
    });
    let arrSimPhoi = listSim.map((sim) => {
      let SimsCopiedPhoi = `${sim.sim_show}`;
      return SimsCopiedPhoi;
    });
    let arrSimPhoiPrice = listSim.map((sim) => {
      let SimsCopiedPhoiPrice = numberFormatText(sim.price);
      return SimsCopiedPhoiPrice;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      const simsPhoi = arrSimPhoi.toString().replace(re, '\n');
      const simsPhoiPrice = arrSimPhoiPrice.toString().replace(re, '\n');
      setTextCopiedSim(sims);
      setTextCopiedSimPhoi(simsPhoi);
      setTextCopiedSimPhoiPrice(simsPhoiPrice);
    }
  };

  const handleTextCopiedSimAndPriceGiaThu = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim_show} = ${numberFormatText(
        resultDiscount.length > 0 && totalPriceDiscount(sim, resultDiscount)[0],
      )}`;

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = `👉 Sim = Giá Thu\n` + arrSim.toString().replace(re, '\n');
      setTextCopiedSimAndPriceGiaThu(sims);
    }
  };

  const handleTextCopiedSimAndPriceGiaThuKoTach = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim} = ${numberFormatText(
        resultDiscount.length > 0 && totalPriceDiscount(sim, resultDiscount)[0],
      )}`;

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = `👉 Sim = Giá Thu\n` + arrSim.toString().replace(re, '\n');
      setTextCopiedSimAndPriceGiaThuKoTach(sims);
    }
  };
  const handleTextCopiedSimAndPriceGiaThuKhach = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    // Chia mảng data theo mảng discount
    const groupedData = {};
    resultDiscount.forEach((d) => {
      groupedData[d.discount] = [];
      listSim.forEach((item) => {
        const price = parseInt(item.price);
        if (price >= d.price_start && price <= d.price_end) {
          groupedData[d.discount].push(item);
        }
      });
    });

    // Hiển thị kết quả
    let result = '';
    Object.keys(groupedData).forEach((discount) => {
      if (groupedData[discount].length > 0) {
        result += `${listSim.length > 1 ? `----------------\n` : ''}💰 GIÁ SALE (CK${discount}%)\n`;
        groupedData[discount].forEach((item) => {
          result += `${iconcopy?.trim()}${item.sim_show} = ${numberFormatText(item.price)}\n`;
        });
      }
    });
    const sims = `👉 Sim Giá Khách. \n🤝 Ae rao giúp nhé.\n` + result;
    setTextCopiedSimAndPriceGiaThuKhach(sims);
  };

  const handleTextCopiedSimAndPrice = (state, iconcopy = iconCopy) => {
    let listSim = Object.values(state);
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `${iconcopy?.trim()}${sim.sim_show} = ${numberFormatText(sim.price)}`;

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      setTextCopiedSimAndPrice(sims);
    }
  };

  const handleTextCopied = (state) => {
    let listSim = Object.values(state);
    var simSo = [];
    var simPrice = 0;
    let arrSim = listSim.map((sim) => {
      let SimsCopied = `- Sim: ${sim.sim_show}\n💰 Giá khách: ${numberFormatText(
        sim.price,
      )} (CK ${numberFormatTextShorten(
        resultDiscount.length > 0 && totalPriceDiscount(sim, resultDiscount)[2],
      )}%) \n👉 Mạng: ${sim.nhamang} \n- Thuê bao: ${sim.thuebao}\n- Tình trạng: sim còn ${
        sim['note_sim'] ? '\n- ' + sim['note_sim'] : ''
      }  ${listSim.length > 1 ? `\n-----------` : ''} `;
      simSo.push(`- Sim mua: ${sim.sim_show}\n👉 Mạng: ${sim.nhamang} ${listSim.length > 1 ? `\n-----------` : ''}`);
      simPrice = numberFormatDeleteDots(simPrice) + numberFormatDeleteDots(sim.price);

      return SimsCopied;
    });
    if (arrSim.length > 0) {
      const re = /,/gi;
      const sims = arrSim.toString().replace(re, '\n');
      const simso = simSo.toString().replace(re, '\n');
      let textSimCopied = `THÔNG TIN CHI TIẾT SIM \n${sims}`;
      let textSimCopiedChot = `✈️𝑻𝒉𝒐̂𝒏𝒈 𝒕𝒊𝒏 𝑪𝒉𝒐̂́𝒕 𝑺𝒊𝒎!!!\n- Tên KH:\n${simso}\n- Liên hệ KH:\n- Địa chỉ Khách: \n ☛ Tổng thu KH : ${numberFormatText(
        simPrice.toString(),
      )}`;
      setTextCopied(textSimCopied);
      setTextCopiedChot(textSimCopiedChot);
    }
  };

  const submitBtnCopied = () => {
    setCheckCoppy({});
    setDataImageExcel([]);
    setTextCopied('');
    setTextCopiedSimAndPrice('');
    setTextCopiedSimAndPriceGiaThu('');
    setTextCopiedSimAndPriceGiaThuKoTach('');
    setTextCopiedSimAndPriceGiaThuKhach('');
    setTextCopiedChot('');
  };

  //thanh tim kiem nang cao
  const toggleDrawer = (open) => {
    setIsOpenDrawer(open);
  };
  const toggleDrawerCate = (open) => {
    setIsOpenDrawerCate({ ...isOpenDrawerCate, isCate: open });
  };

  // handle tranh so
  const handleTranhSo = (val) => {
    const i = actionTranhso.indexOf(val);
    if (i > -1) {
      const checkDelSo = actionTranhso.filter((item) => item !== val);
      setActionTranhso(checkDelSo);
    } else {
      const stateTS = [...actionTranhso, val];
      setActionTranhso(stateTS);
    }
  };
  const handleSttTranhso = (e) => {
    setIsSttTranhso(e.target.value);
    setActionTranhso([]);
  };

  // handle cắt chuỗi loại sim
  const handleCutCateTitle = (str) => {
    const array = str.split(' ');
    let title = '';
    if (array.length < 4) {
      title = `${array.length > 0 ? array[0] : ''} ${array.length >= 2 ? array[1] : ''} ${
        array.length === 3 ? array[2] : ''
      }`;
    } else {
      title = `${array[0]} ${array[1]} ${array[2]}...`;
    }
    return title;
  };

  const handleChangeSearchSim = (e) => {
    setCheckCoppy({});
    setDataImageExcel([]);
    setTextSearchChange(e.target.value);
    setTextSearch(e.target.value);
    setSimInfinteResult({ data: [] });
    setSimInfinteResultCount({ totalRows: 0 });
  };

  const onClickSearchSubmit = () => {
    setTextSearch(textSearchChange);
    setCheckCoppy({});
    setDataImageExcel([]);
    fetchApiLocSim(
      textSearchChange,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      actionRangePriceSim,
      actionTranhso.join(''),
      thuebao,
    );
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      setTextSearch(e.target.value);
      setCheckCoppy({});
      setDataImageExcel([]);
      fetchApiLocSim(
        textSearchChange,
        actionNetWork,
        actionMenuCate,
        actionFormatSim,
        actionMenhSim,
        actionBtnSort,
        actionRangePriceSim,
        actionTranhso.join(''),
        thuebao,
      );
    }
  };

  const handleChangStartPrice = (e) => {
    setActionRangePrice({ ...actionRangePrice, startPrice: e.target.value });
    setActionRangePriceSim(
      (numberFormatDeleteDots(numberFormatDeleteDots(e.target.value)) / 1000000 || 0) +
        ',' +
        (numberFormatDeleteDots(actionRangePrice?.endPrice) / 1000000 || 0),
    );
  };

  const handleChangEndPrice = (e) => {
    setActionRangePrice({ ...actionRangePrice, endPrice: e.target.value });
    setActionRangePriceSim(
      (numberFormatDeleteDots(actionRangePrice?.startPrice) / 1000000 || 0) +
        ',' +
        (numberFormatDeleteDots(e.target.value) / 1000000 || 0),
    );
  };

  const handleSearchRangePrice = () => {
    setOpenRangePrice(false);
    fetchApiLocSim(
      textSearch,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      actionRangePriceSim,
      actionTranhso.join(''),
      thuebao,
    );
  };

  const resetSearchRangePriceDialog = () => {
    setActionRangePriceSim('');
    setActionRangePrice({ startPrice: '', endPrice: '' });
    fetchApiLocSim(
      textSearch,
      actionNetWork,
      actionMenuCate,
      actionFormatSim,
      actionMenhSim,
      actionBtnSort,
      '',
      actionTranhso.join(''),
      thuebao,
    );
  };

  const handleIsInFoImage = (value) => {
    const arrIsImage = { ...isCheckInFoIMG, ...value };
    setIsCheckInFoIMG(arrIsImage);
  };

  const handleIsFocus = (isFocused) => {
    setIsFocused(true);
    setIsScroll(true);
  };

  const handleNameCate = (titleSim, cateSim, menhSim, formatSim) => {
    let arrFavourite = '';
    if (favourite(titleSim)) {
      arrFavourite = `Sim Đại Cát`;
    } else if (menuSimResult.filter((x) => x.link === cateSim?.split(',')[0])?.length > 0) {
      arrFavourite = menuSimResult.filter((x) => x.link === cateSim?.split(',')[0])[0]?.title;
    } else if (LogicCategoryShowName(cateSim?.split(',')[0])?.length > 0) {
      arrFavourite = LogicCategoryShowName(cateSim.split(',')[0]);
    } else if (isSimDaily) {
      arrFavourite = cateSim;
    } else if (LogicCategoryShowName(menhSim?.split(',')[0])?.length > 0) {
      arrFavourite = LogicCategoryShowName(menhSim?.split(',')[0]);
    } else {
      arrFavourite = `Dạng ${actionFormatSim?.length > 0 ? actionFormatSim : formatSim?.split(',')[0]}`;
    }
    return arrFavourite;
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('domain', hostname);
    formData.append('id', authDomain['id']);
    setLoadingTheme(true);
    setLoadingList(true);
    const resultAPISIM = await listSimService.uploadFileSim(formData);
    if (resultAPISIM['message'] === 'Ok') {
      fetchApiSim();
    } else {
      setLoadingTheme(false);
      setLoadingList(false);
    }
  };

  const handleDownload = () => {
    const fileUrl = images.filemausim;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'mau-upload-sim.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const convertDataToText = () => {
  //   let text = '';
  //   if (isCheckInFoIMG.isPrice) {
  //     dataImageExcel?.forEach((item) => {
  //       text += `${item.sim_show}\n`;
  //     });
  //     return text;
  //   } else {
  //     dataImageExcel?.forEach((item) => {
  //       text += `${item.sim_show}=${numberFormatText(item.price)}\n`;
  //     });
  //     return text.trim();
  //   }
  // };
  const convertTextToArray = (data) => {
    if (data) {
      const dataArray = data.split('\n').map((item) => {
        if (isCheckInFoIMG.isPrice) {
          return { sim_show: item || '' };
        } else {
          const [name, price] = item.split('=');
          return { sim_show: name, price: price || '' };
        }
      });
      setDataImageExcel(dataArray);
    } else {
      setDataImageExcel([]);
    }
  };

  const addKeepSim = async (sim, idsim) => {
    const simId = await listSimService.checkSimId(hostname, sim);
    if (simId.data.length > 0) {
      if (simId.data[0].status === 1) {
        const today = new Date();
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 24);

        // Extract day, month, and year
        let day = today.getDate();
        let month = today.getMonth() + 1;
        let year = today.getFullYear();

        let daytor = expirationTime.getDate();
        let monthtor = expirationTime.getMonth() + 1;
        let yeartor = expirationTime.getFullYear();

        // Add leading zero to day and month if needed
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        daytor = daytor < 10 ? '0' + daytor : daytor;
        monthtor = monthtor < 10 ? '0' + monthtor : monthtor;

        // Format the date as dd/mm/yyyy
        const formattedDate = `${day}/${month}/${year}`;

        const time =
          formattedDate +
          'T' +
          new Date().toLocaleTimeString('vi-VN', {
            timeZone: 'Asia/Ho_Chi_Minh',
          });

        // const compareTime = new Date(`${year}-${month}-${day}T00:00:00`);
        // let timeend =
        //   today.getTime() < compareTime.getTime()
        //     ? formattedDate + 'T00:00:00'
        //     : `${day + 1}/${month}/${year}T00:00:00`;
        let timeend =
          `${daytor}/${monthtor}/${yeartor}` +
          'T' +
          new Date().toLocaleTimeString('vi-VN', {
            timeZone: 'Asia/Ho_Chi_Minh',
          });

        const body = {
          sim: sim,
          timestart: time,
          timeend: timeend,
          domain: hostname,
          username: getToken?.username,
          user_id: getToken?.id,
          status: 1,
        };

        await addKeepSimSo(body);
        const bodysim = {
          id: idsim,
          data: { status: 3 },
        };
        const resultAPI = await listSimService.updateSim(bodysim);
        if (resultAPI?.message === 'Ok') {
          getParams();
          setOpenSnac(true);
          setErrorSnac({ isError: false, message: 'Đã giữ sim thành công!' });
        }
      } else {
        setOpenSnac(true);
        setErrorSnac({ isError: true, message: 'Đã có người giữ sim này. Vui lòng load lại trang' });
      }
    }
  };
  return (
    <>
      <Container sx={{ p: 0, pb: '90px' }}>
        <Dialog open={openRangePrice} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title" color={'red'}>
            {'Lọc sim theo khoảng giá'}
          </DialogTitle>
          <Grid container>
            <Grid container sx={{ m: 1 }}>
              <Grid item xs={6} className={cx('dis-center-1')}>
                <TextField
                  onChange={handleChangStartPrice}
                  value={actionRangePrice.startPrice ? numberFormat(actionRangePrice.startPrice) : ''}
                  label="giá từ"
                  variant="outlined"
                  error
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={handleChangEndPrice}
                  value={actionRangePrice.endPrice ? numberFormat(actionRangePrice.endPrice) : ''}
                  label="đến giá"
                  variant="outlined"
                  error
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1, ml: 1 }}>
              <h4>
                <i>Gợi ý sẵn</i>
              </h4>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              {priceRange.map((row, i) => (
                <ButtonUI
                  key={i}
                  sx={{ mb: 1, mr: 0.5, ml: 0.5 }}
                  startIcon={<MonetizationOnIcon />}
                  bgrColorBtn={actionRangePriceSim === row.value ? bgrDF.bgr + '26' : '#F3F4F6'}
                  colorText={actionRangePriceSim === row.value ? bgrDF.bgr : '#444444'}
                  borderBtn={actionRangePriceSim === row.value ? bgrDF.bgr + '4a' : '#4444444f'}
                  onClick={() => {
                    setActionRangePriceSim(actionRangePriceSim === row.value ? '' : row.value);
                    fetchApiLocSim(
                      textSearch,
                      actionNetWork,
                      actionMenuCate,
                      actionFormatSim,
                      actionMenhSim,
                      actionBtnSort,
                      actionRangePriceSim === row.value ? '' : row.value,
                      actionTranhso.join(''),
                      thuebao,
                    );
                    setOpenRangePrice(false);
                  }}
                >
                  {row.titlePrice}
                </ButtonUI>
              ))}
            </Grid>
          </Grid>
          <DialogActions>
            <ButtonUI onClick={handleClose}>Đóng</ButtonUI>
            <ButtonUI onClick={resetSearchRangePriceDialog} color="warning">
              Xóa Tìm Kiếm Hiện Tại
            </ButtonUI>
            <ButtonUI onClick={handleSearchRangePrice} color="error">
              Tìm Kiếm
            </ButtonUI>
          </DialogActions>
        </Dialog>
        {/* CHIẾT KHẤU BẢNG SIM  */}
        <Dialog
          fullScreen
          open={openDiscount}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AppBar sx={{ position: 'relative', background: `var(--home-color)` }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                BẢNG CHIẾT KHẤU GIÁ SIM
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText>
              <Discount />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>ĐÓNG</Button>
          </DialogActions>
        </Dialog>
        <Fragment>
          <Drawer
            anchor={'right'}
            open={isOpenDrawer}
            onClose={() => toggleDrawer(false)}
            sx={{
              '& .MuiPaper-root': {
                borderTopLeftRadius: '10px',
              },
            }}
          >
            <label className={cx('title-sort-sim')}>
              <FilterAltRoundedIcon />
              <b>BỘ LỌC SIM</b>
            </label>
            <Box sx={{ width: 250, p: 1 }}>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>DANH MỤC SIM</b>
                </label>
                <FormControl size="small">
                  <Select
                    sx={{
                      borderRadius: 2.5,
                      fontSize: '0.8rem',
                      ':active': bgrDF.bgr + '4a',
                    }}
                    value={actionMenuCate}
                  >
                    <MenuItem
                      onClick={() => {
                        setActionMenuCate(0);
                      }}
                      value="0"
                    >
                      Loại sim
                    </MenuItem>
                    {menuSimResult.map((menu) => (
                      <MenuItem
                        key={menu.link}
                        value={menu.link}
                        onClick={() => {
                          setActionMenuCate(menu.link);
                        }}
                      >
                        {menu.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>MỆNH SIM</b>
                </label>
                <FormControl size="small">
                  <Select
                    sx={{
                      borderRadius: 2.5,
                      fontSize: '0.8rem',
                      ':active': bgrDF.bgr + '4a',
                    }}
                    value={actionMenhSim}
                  >
                    <MenuItem
                      onClick={() => {
                        setActionMenhSim(0);
                      }}
                      value="0"
                    >
                      Chọn Mệnh
                    </MenuItem>
                    {arrayMenhSim.map((menh) => (
                      <MenuItem
                        key={menh.link}
                        value={menh.link}
                        onClick={() => {
                          setActionMenhSim(menh.link);
                        }}
                      >
                        {menh.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>THUÊ BAO</b>
                </label>
                <FormControl size="small">
                  <Select
                    sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                    value={thuebao}
                    onChange={(e) => {
                      setThuebao(e.target.value);
                    }}
                    displayEmpty
                  >
                    <MenuItem value="">Thuê bao</MenuItem>
                    <MenuItem value={'Trả trước'}>Trả trước</MenuItem>
                    <MenuItem value={'Trả sau'}>Trả sau</MenuItem>
                    <MenuItem value={'Trả trước cam kết'}>Trả trước cam kết</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>CHỌN MẠNG</b>
                </label>
                <Box spacing={1}>
                  {homeNetWork.map((network, index) => (
                    <ButtonUI
                      key={index}
                      borderRadius={6}
                      bgrColorBtn={actionNetWork === network.titleNetWork ? bgrDF.bgr + '26' : '#ffffff'}
                      borderBtn={actionNetWork === network.titleNetWork ? bgrDF.bgr : '#44444426'}
                      onClick={() => {
                        setActionNetWork(actionNetWork === network.titleNetWork ? '' : network.titleNetWork);
                      }}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {network.titleNetWork}
                    </ButtonUI>
                  ))}
                </Box>
              </Stack>

              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>CHỌN KHOẢNG GIÁ</b>
                </label>
                <Box spacing={1}>
                  {priceRange.map((price, index) => (
                    <ButtonUI
                      key={index}
                      borderRadius={6}
                      bgrColorBtn={actionRangePriceSim === price.value ? bgrDF.bgr + '26' : '#ffffff'}
                      borderBtn={actionRangePriceSim === price.value ? bgrDF.bgr : '#44444426'}
                      onClick={() => {
                        setActionRangePriceSim(actionRangePriceSim === price.value ? '' : price.value);
                      }}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {price.titlePrice}
                    </ButtonUI>
                  ))}
                </Box>
              </Stack>

              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>ĐẦU SỐ</b>
                </label>
                <Box spacing={1}>
                  {searchDauSoSim.map((dauso, index) => (
                    <ButtonUI
                      key={index}
                      borderRadius={6}
                      bgrColorBtn={searchDauSim === dauso.dauso ? bgrDF.bgr + '26' : '#ffffff'}
                      borderBtn={searchDauSim === dauso.dauso ? bgrDF.bgr : '#44444426'}
                      onClick={() => handleChangeSelectDauSoDrawer(dauso.dauso === searchDauSim ? '' : dauso.dauso)}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {dauso.titleDauSo}
                    </ButtonUI>
                  ))}
                </Box>
              </Stack>

              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>TRÁNH SỐ</b>
                </label>
                <Stack direction="row" spacing={1}>
                  <RadioGroup value={isSttTranhso} onChange={handleSttTranhso} row>
                    <FormControlLabel
                      value="1"
                      control={<Radio size="small" />}
                      label={<Typography sx={{ fontSize: 14 }}>Tránh 6 sô</Typography>}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio size="small" />}
                      label={<Typography sx={{ fontSize: 14 }}>Tránh tất cả</Typography>}
                    />
                  </RadioGroup>
                </Stack>
                <Box spacing={1}>
                  {searchTranhso.map((sim, index) => (
                    <ButtonUI
                      key={index}
                      borderRadius={6}
                      bgrColorBtn={actionTranhso.indexOf(sim) > -1 ? bgrDF.bgr + '26' : '#ffffff'}
                      borderBtn={actionTranhso.indexOf(sim) > -1 ? bgrDF.bgr : '#44444426'}
                      onClick={() => handleTranhSo(sim)}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {sim}
                    </ButtonUI>
                  ))}
                </Box>
              </Stack>

              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>TỔNG SỐ ĐẦU - SỐ CUỐI</b>
                </label>
                <Stack direction="row" spacing={1} marginBottom="10px" alignItems={'center'}>
                  <label>Số đầu:</label>
                  <TextField
                    label="vd: 5"
                    variant="outlined"
                    size="small"
                    value={actionSimStart.sodau}
                    onChange={(e) => setActionSimStart({ ...actionSimStart, sodau: e.target.value })}
                  />
                  <label>=</label>
                  <TextField
                    label="vd: 23"
                    variant="outlined"
                    size="small"
                    value={actionSimStart.totalSoDau}
                    onChange={(e) => setActionSimStart({ ...actionSimStart, totalSoDau: e.target.value })}
                  />
                </Stack>
                <Stack direction="row" spacing={1} marginBottom="10px" alignItems={'center'}>
                  <label>Số cuối:</label>
                  <TextField
                    label="vd: 5"
                    variant="outlined"
                    size="small"
                    value={actionSimEnd.socuoi}
                    onChange={(e) => setActionSimEnd({ ...actionSimEnd, socuoi: e.target.value })}
                  />
                  <label>=</label>
                  <TextField
                    label="vd: 36"
                    variant="outlined"
                    size="small"
                    value={actionSimEnd.totalSoCuoi}
                    onChange={(e) => setActionSimEnd({ ...actionSimEnd, totalSoCuoi: e.target.value })}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} marginBottom="10px" justifyContent={'flex-end'}>
                <ButtonUI
                  color="error"
                  borderBtn="#ff0000"
                  borderRadius={4}
                  sx={{ mr: 0.5, mb: 0.5 }}
                  onClick={() => {
                    toggleDrawer(false);
                    fetchApiLocSim(
                      textSearch,
                      actionNetWork,
                      actionMenuCate === 0 ? '' : actionMenuCate,
                      actionFormatSim,
                      actionMenhSim,
                      actionBtnSort,
                      actionRangePriceSim,
                      actionTranhso.join(''),
                      thuebao,
                    );
                  }}
                >
                  XEM KẾT QUẢ
                </ButtonUI>
              </Stack>
            </Box>
          </Drawer>
        </Fragment>

        <Fragment>
          <Drawer
            anchor={'bottom'}
            open={isOpenDrawerCate.isCate}
            onClose={() => toggleDrawerCate(false)}
            sx={{
              '& .MuiPaper-root': {
                borderTopLeftRadius: '10px',
              },
            }}
          >
            {isOpenDrawerCate.cateNumber === 1 && (
              <label className={cx('title-sort-sim')}>
                <DashboardCustomizeOutlinedIcon />
                <b>THỂ LOẠI SIM</b>
              </label>
            )}
            {isOpenDrawerCate.cateNumber === 2 && (
              <Stack
                direction={'row'}
                className={cx('title-sort-sim')}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <label className={cx('dis-center')}>
                  <ClassOutlinedIcon />
                  <b>SIM ĐỊNH DẠNG</b>
                </label>
                <b onClick={() => toggleDrawerCate(false)}>Đóng lại</b>
              </Stack>
            )}
            <Box sx={{ p: 1 }}>
              <Stack direction="column" spacing={1} marginBottom="10px">
                <label>
                  <b>{isOpenDrawerCate.cateNumber === 1 && `CHỌN SỐ THEO THỂ LOẠI`}</b>
                  <b>{isOpenDrawerCate.cateNumber === 2 && `CHỌN SỐ THEO ĐỊNH DẠNG`}</b>
                </label>
                {isOpenDrawerCate.cateNumber === 1 && (
                  <Box>
                    {menuSimResult.map((menu, index) => (
                      <ButtonUI
                        key={index}
                        borderRadius={6}
                        bgrColorBtn={actionMenuCate === menu.link ? bgrDF.bgr + '26' : '#ffffff'}
                        borderBtn={actionMenuCate === menu.link ? bgrDF.bgr : '#44444426'}
                        onClick={() => {
                          setActionMenuCate(actionMenuCate === menu.link ? 0 : menu.link);
                          setCheckCoppy({});
                          setDataImageExcel([]);
                          fetchApiLocSim(
                            textSearch,
                            actionNetWork,
                            actionMenuCate === menu.link ? '' : menu.link,
                            actionFormatSim,
                            actionMenhSim,
                            actionBtnSort,
                            actionRangePriceSim,
                            actionTranhso.join(''),
                            thuebao,
                          );
                          toggleDrawerCate(false);
                        }}
                        sx={{ mr: 0.5, mb: 0.5 }}
                      >
                        {menu.title}
                      </ButtonUI>
                    ))}
                  </Box>
                )}
                {isOpenDrawerCate.cateNumber === 2 && (
                  <Box>
                    <Stack direction={'row'}>
                      <TextField
                        label="NHẬP DẠNG SIM"
                        id="outlined-start-adornment"
                        sx={{ m: 1 }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{`A->H:`}</InputAdornment>,
                        }}
                        size="small"
                        defaultValue={actionFormatSim === 0 ? '' : actionFormatSim}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            setActionFormatSim(e.target.value);
                            setCheckCoppy({});
                            setDataImageExcel([]);
                            fetchApiLocSim(
                              textSearch,
                              actionNetWork,
                              actionMenuCate,
                              e.target.value,
                              actionMenhSim,
                              actionBtnSort,
                              actionRangePriceSim,
                              actionTranhso.join(''),
                              thuebao,
                            );
                            toggleDrawerCate(false);
                          }
                        }}
                      />
                    </Stack>
                    {arrayFormatCate.map((menu, index) => (
                      <ButtonUI
                        key={index}
                        borderRadius={6}
                        bgrColorBtn={actionFormatSim === menu.link ? bgrDF.bgr + '26' : '#ffffff'}
                        borderBtn={actionFormatSim === menu.link ? bgrDF.bgr : '#44444426'}
                        onClick={() => {
                          setActionFormatSim(actionFormatSim === menu.link ? 0 : menu.link);
                          fetchApiLocSim(
                            textSearch,
                            actionNetWork,
                            actionMenuCate,
                            actionFormatSim === menu.link ? '' : menu.link,
                            actionMenhSim,
                            actionBtnSort,
                            actionRangePriceSim,
                            actionTranhso.join(''),
                            thuebao,
                          );
                          toggleDrawerCate(false);
                          setCheckCoppy({});
                          setDataImageExcel([]);
                        }}
                        sx={{ mr: 0.5, mb: 0.5 }}
                      >
                        {menu.title}
                      </ButtonUI>
                    ))}
                  </Box>
                )}
              </Stack>
            </Box>
          </Drawer>
        </Fragment>

        <Grid container spacing={0.5} justifyContent={'center'}>
          <Grid item md={10} xs={12} sx={{ mt: 0.5 }}>
            <Hidden only={['md', 'lg', 'xl', 'sm', 'xs']}>
              <Grid item md={12} xs={12}>
                <div className={cx('wrapper-content')}>
                  <div className={cx('main-content-cate-border')}>
                    <div>
                      <div className={cx('list-subcate')}>
                        <Stack spacing={2} direction="row">
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            onClick={() => {
                              setActionMenuCate(0);
                              handleClearSim();
                            }}
                            className={cx('cate-border')}
                            sx={{
                              background: '#e1e00021',
                            }}
                          >
                            <Badge
                              color={actionMenuCate === 0 ? 'success' : 'default'}
                              overlap="circular"
                              badgeContent=" "
                              variant="dot"
                              sx={{ mb: 0.5 }}
                            >
                              <Avatar
                                sx={{
                                  background:
                                    actionMenuCate === 0
                                      ? 'linear-gradient(-180deg, deeppink, crimson)'
                                      : `linear-gradient(180deg, #ff933f, #f93782 69%, #f93782)`,
                                }}
                              >
                                <b>T</b>
                              </Avatar>
                            </Badge>
                            <b className={cx('bgr-title-cate')}>Tổng Sim</b>
                          </Box>
                          {menuSimResult.map((menu, i) => (
                            <Box
                              key={menu.link}
                              display={'flex'}
                              flexDirection={'column'}
                              alignItems={'center'}
                              onClick={() => {
                                menu.link !== actionMenuCate ? setActionMenuCate(menu.link) : setActionMenuCate(0);
                              }}
                              className={cx('cate-border')}
                              sx={{
                                background: bgrCateSim[i],
                              }}
                            >
                              <Badge
                                color={actionMenuCate === menu.link ? 'success' : 'default'}
                                overlap="circular"
                                badgeContent=" "
                                variant="dot"
                                sx={{ mb: 0.5 }}
                              >
                                <Avatar
                                  sx={{
                                    background:
                                      actionMenuCate === menu.link
                                        ? `linear-gradient(-180deg, deeppink, crimson)`
                                        : `linear-gradient(180deg, #ff933f, #f93782 69%, #f93782)`,
                                  }}
                                >
                                  <b>{menu.title?.slice(0, 1)}</b>
                                </Avatar>
                              </Badge>
                              <b className={cx('bgr-title-cate')}> {handleCutCateTitle(menu.title)}</b>
                            </Box>
                          ))}
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Hidden>
            {/* {authDomain['background'] && ( */}
            <>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <header
                    className={cx(
                      authDomain['background'] === '#f37c02,0deg'
                        ? 'wrapper-header-gold'
                        : authDomain['background'] === '#1b1b1b,0deg'
                        ? 'wrapper-header-black'
                        : 'wrapper-header',
                    )}
                  >
                    <div className={cx('inner-header')}>
                      <Grid container>
                        <Grid item md={12} xs={12}>
                          <Paper
                            elevation={3}
                            sx={{ p: '0px 2px', display: 'flex', alignItems: 'center', borderRadius: 2 }}
                          >
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              placeholder={`Tìm sim trên ${hostname}`}
                              inputProps={{ 'aria-label': 'Tìm kiếm sim số' }}
                              value={textSearchChange}
                              onChange={handleChangeSearchSim}
                              onKeyPress={handleEnterKey}
                              fullWidth
                            />
                            {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                            <IconButton
                              sx={{ m: '2px' }}
                              className={cx('bgr-default')}
                              aria-label="directions"
                              onClick={onClickSearchSubmit}
                            >
                              <SearchIcon sx={{ color: 'aliceblue' }} />
                            </IconButton>
                          </Paper>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 1 }}>
                          <label className={cx('color-trang')}>
                            <b>Tìm sim đuôi 3979 gõ *3979 - 09 đuôi bất kỳ gõ 09*</b>
                          </label>
                        </Grid>
                      </Grid>
                    </div>
                  </header>
                </Grid>
              </Grid>

              <div className={cx('main-content-filter')}>
                <div className={cx('main-content-filter-bgr')}>
                  <div className={cx('box-list-subcate')}>
                    <div className={cx('list-subcate')}>
                      <Stack direction="row" spacing={1}>
                        {homeNetWork.map((network, index) => (
                          <ButtonUI
                            key={index}
                            borderRadius={6}
                            bgrColorBtn={
                              actionNetWork === network.titleNetWork
                                ? bgrDF.bgr
                                  ? bgrDF.bgr + '26'
                                  : '#cf000e26'
                                : '#ffffff'
                            }
                            borderBtn={
                              actionNetWork === network.titleNetWork ? (bgrDF.bgr ? bgrDF.bgr : '#cf000e') : '#44444426'
                            }
                            onClick={() => {
                              network.titleNetWork !== actionNetWork
                                ? setActionNetWork(network.titleNetWork)
                                : setActionNetWork('');
                              fetchApiLocSim(
                                textSearch,
                                actionNetWork === network.titleNetWork ? '' : network.titleNetWork,
                                actionMenuCate,
                                actionFormatSim,
                                actionMenhSim,
                                actionBtnSort,
                                actionRangePriceSim,
                                actionTranhso.join(''),
                                thuebao,
                              );
                              setCheckCoppy({});
                              setDataImageExcel([]);
                            }}
                          >
                            <img src={network.srcNetwork} alt={network.titleNetWork} width={network.width} />
                          </ButtonUI>
                        ))}
                      </Stack>
                    </div>
                  </div>

                  <div className={cx('block-list-filter')}>
                    <label className={cx('block-list-filter_title')}>Theo tiêu chí</label>
                    <div className={cx('box-list-subcate')}>
                      <div className={cx('list-subcate')}>
                        <Stack direction="row" spacing={1}>
                          <ButtonUI
                            startIcon={<FilterAltRoundedIcon />}
                            bgrColorBtn={
                              isOpenDrawer === true ? (bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26') : '#F3F4F6'
                            }
                            colorText={isOpenDrawer === true ? (bgrDF.bgr ? bgrDF.bgr : '#cf000e') : '#444444'}
                            borderBtn={
                              isOpenDrawer === true ? (bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a') : '#4444440d'
                            }
                            onClick={() => {
                              toggleDrawer(true);
                              setCheckCoppy({});
                              setDataImageExcel([]);
                            }}
                          >
                            Bộ Lọc
                          </ButtonUI>

                          <ButtonUI
                            startIcon={<DashboardCustomizeOutlinedIcon />}
                            bgrColorBtn={
                              menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                                ? bgrDF.bgr
                                  ? bgrDF.bgr + '26'
                                  : '#cf000e26'
                                : '#F3F4F6'
                            }
                            colorText={
                              menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                                ? bgrDF.bgr
                                  ? bgrDF.bgr
                                  : '#cf000e'
                                : '#444444'
                            }
                            borderBtn={
                              menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                                ? bgrDF.bgr
                                  ? bgrDF.bgr + '4a'
                                  : '#cf000e4a'
                                : '#4444440d'
                            }
                            onClick={() => setIsOpenDrawerCate({ isCate: true, cateNumber: 1 })}
                          >
                            {menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                              ? menuSimResult.filter((x) => x.link === actionMenuCate)[0].title
                              : `Thể Loại`}
                          </ButtonUI>

                          <ButtonUI
                            startIcon={<MonetizationOnIcon />}
                            bgrColorBtn={
                              openRangePrice === true ? (bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26') : '#F3F4F6'
                            }
                            colorText={openRangePrice === true ? (bgrDF.bgr ? bgrDF.bgr : '#cf000e') : '#444444'}
                            borderBtn={
                              openRangePrice === true ? (bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a') : '#3131313b'
                            }
                            onClick={() => {
                              setOpenRangePrice(true);
                              setCheckCoppy({});
                              setDataImageExcel([]);
                            }}
                          >
                            {actionRangePriceSim
                              ? priceRange.find((price) => price.value === actionRangePriceSim)?.titlePrice ||
                                numberFormatText(actionRangePrice.startPrice) +
                                  ' - ' +
                                  numberFormatText(actionRangePrice.endPrice)
                              : 'Khoảng giá'}
                          </ButtonUI>

                          <ButtonUI
                            startIcon={<ClassOutlinedIcon />}
                            bgrColorBtn={
                              arrayFormatCate.filter((x) => x.link === actionFormatSim).length > 0
                                ? bgrDF.bgr
                                  ? bgrDF.bgr + '26'
                                  : '#cf000e26'
                                : '#F3F4F6'
                            }
                            colorText={
                              arrayFormatCate.filter((x) => x.link === actionFormatSim).length > 0
                                ? bgrDF.bgr
                                  ? bgrDF.bgr
                                  : '#cf000e'
                                : '#444444'
                            }
                            borderBtn={
                              arrayFormatCate.filter((x) => x.link === actionFormatSim).length > 0
                                ? bgrDF.bgr
                                  ? bgrDF.bgr + '4a'
                                  : '#cf000e4a'
                                : '#4444440d'
                            }
                            onClick={() => setIsOpenDrawerCate({ isCate: true, cateNumber: 2 })}
                          >
                            {arrayFormatCate.filter((x) => x.link === actionFormatSim).length > 0
                              ? arrayFormatCate.filter((x) => x.link === actionFormatSim)[0].title
                              : `Định Dạng`}
                          </ButtonUI>

                          <FormControl size="small">
                            <Select
                              sx={{
                                borderRadius: 2.5,
                                fontSize: '0.8rem',
                                ':active': bgrDF.bgr + '4a',
                              }}
                              value={actionMenhSim}
                            >
                              <MenuItem
                                onClick={() => {
                                  setActionMenhSim(0);
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                  fetchApiLocSim(
                                    textSearch,
                                    actionNetWork,
                                    actionMenuCate,
                                    actionFormatSim,
                                    '',
                                    actionBtnSort,
                                    actionRangePriceSim,
                                    actionTranhso.join(''),
                                    thuebao,
                                  );
                                }}
                                value="0"
                              >
                                Mệnh sim
                              </MenuItem>
                              {arrayMenhSim.map((menh) => (
                                <MenuItem
                                  key={menh.link}
                                  value={menh.link}
                                  onClick={() => {
                                    setActionMenhSim(menh.link);
                                    setCheckCoppy({});
                                    setDataImageExcel([]);
                                    fetchApiLocSim(
                                      textSearch,
                                      actionNetWork,
                                      actionMenuCate,
                                      actionFormatSim,
                                      menh.link,
                                      actionBtnSort,
                                      actionRangePriceSim,
                                      actionTranhso.join(''),
                                      thuebao,
                                    );
                                  }}
                                >
                                  {menh.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl size="small">
                            <Select
                              sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                              value={searchDauSim}
                              onChange={(e) => {
                                handleChangeSelectDauSo(e);
                                setCheckCoppy({});
                                setDataImageExcel([]);
                              }}
                              displayEmpty
                            >
                              <MenuItem value="">Đầu số</MenuItem>
                              <MenuItem value={'09*'}>Đầu số 09</MenuItem>
                              <MenuItem value={'07*'}>Đầu số 07</MenuItem>
                              <MenuItem value={'03*'}>Đầu số 03</MenuItem>
                              <MenuItem value={'08*'}>Đầu số 08</MenuItem>
                              <MenuItem value={'05*'}>Đầu số 05</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl size="small">
                            <Select
                              sx={{ borderRadius: 2.5, fontSize: '0.8rem' }}
                              value={thuebao}
                              onChange={(e) => {
                                setThuebao(e.target.value);
                                fetchApiLocSim(
                                  textSearch,
                                  actionNetWork,
                                  actionMenuCate,
                                  actionFormatSim,
                                  actionMenhSim,
                                  actionBtnSort,
                                  actionRangePriceSim,
                                  actionTranhso.join(''),
                                  e.target.value,
                                );
                                setCheckCoppy({});
                                setDataImageExcel([]);
                              }}
                              displayEmpty
                            >
                              <MenuItem value="">Thuê bao</MenuItem>
                              <MenuItem value={'Trả trước'}>Trả trước</MenuItem>
                              <MenuItem value={'Trả sau'}>Trả sau</MenuItem>
                              <MenuItem value={'Trả trước cam kết'}>Trả trước cam kết</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </div>
                    </div>
                  </div>

                  <div className={cx('block-list-filter')}>
                    <label className={cx('block-list-filter_title')}>Sắp xếp theo</label>
                    <div className={cx('box-list-subcate')}>
                      <div className={cx('list-subcate')}>
                        <Stack direction="row" spacing={1}>
                          <ButtonUI
                            startIcon={<LocalFireDepartmentIcon />}
                            bgrColorBtn={
                              actionBtnSort === '' ? (bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26') : '#F3F4F6'
                            }
                            colorText={actionBtnSort === '' ? (bgrDF.bgr ? bgrDF.bgr : '#cf000e') : '#444444'}
                            borderBtn={
                              actionBtnSort === '' ? (bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a') : '#4444440d'
                            }
                            onClick={() => {
                              setActionBtnSort('');
                              setCheckCoppy({});
                              setDataImageExcel([]);
                              fetchApiLocSim(
                                textSearch,
                                actionNetWork,
                                actionMenuCate,
                                actionFormatSim,
                                actionMenhSim,
                                '',
                                actionRangePriceSim,
                                actionTranhso.join(''),
                                thuebao,
                              );
                            }}
                          >
                            Nổi Bật
                          </ButtonUI>
                          <ButtonUI
                            startIcon={<SouthIcon />}
                            bgrColorBtn={
                              actionBtnSort === 'ASC' ? (bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26') : '#F3F4F6'
                            }
                            colorText={actionBtnSort === 'ASC' ? (bgrDF.bgr ? bgrDF.bgr : '#cf000e') : '#444444'}
                            borderBtn={
                              actionBtnSort === 'ASC' ? (bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a') : '#4444440d'
                            }
                            onClick={() => {
                              setActionBtnSort('ASC');
                              setCheckCoppy({});
                              setDataImageExcel([]);
                              fetchApiLocSim(
                                textSearch,
                                actionNetWork,
                                actionMenuCate,
                                actionFormatSim,
                                actionMenhSim,
                                'ASC',
                                actionRangePriceSim,
                                actionTranhso.join(''),
                                thuebao,
                              );
                            }}
                          >
                            Giá Thấp
                          </ButtonUI>
                          <ButtonUI
                            startIcon={<NorthIcon />}
                            bgrColorBtn={
                              actionBtnSort === 'DESC' ? (bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26') : '#F3F4F6'
                            }
                            colorText={actionBtnSort === 'DESC' ? (bgrDF.bgr ? bgrDF.bgr : '#cf000e') : '#444444'}
                            borderBtn={
                              actionBtnSort === 'DESC' ? (bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a') : '#4444440d'
                            }
                            onClick={() => {
                              setActionBtnSort('DESC');
                              setCheckCoppy({});
                              setDataImageExcel([]);
                              fetchApiLocSim(
                                textSearch,
                                actionNetWork,
                                actionMenuCate,
                                actionFormatSim,
                                actionMenhSim,
                                'DESC',
                                actionRangePriceSim,
                                actionTranhso.join(''),
                                thuebao,
                              );
                            }}
                          >
                            Giá Cao
                          </ButtonUI>
                        </Stack>
                      </div>
                    </div>
                  </div>
                  {getToken?.permission !== '5' && (
                    <div className={cx('block-list-filter')}>
                      <label className={cx('block-list-filter_title')} style={{ color: 'red' }}>
                        UPLOAD SIM TỪ THIẾT BỊ
                      </label>
                      <div className={cx('box-list-subcate')}>
                        <div className={cx('list-subcate')}>
                          <Stack direction="row" spacing={1}>
                            <ButtonUI
                              borderBtn="#009688"
                              bgrColorBtn="#009688"
                              colorText="#fff"
                              startIcon={<LoopIcon />}
                              onClick={fetchApiSimNew}
                            >
                              ĐỒNG BỘ TỪ TRANG TÍNH
                            </ButtonUI>
                            <ButtonUI component="label" color="success" startIcon={<DriveFolderUploadIcon />}>
                              UPLOAD SIM TỪ FILE EXCEL (.xlsx)
                              <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
                            </ButtonUI>
                            <ButtonUI
                              borderBtn="#00502f"
                              colorText="#00502f"
                              bgrColorBtn="#00502f0d"
                              startIcon={<TaskIcon />}
                              onClick={handleDownload}
                            >
                              TẢI MẪU SIM FILE EXCEL
                            </ButtonUI>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  )}
                  {(textSearch !== '' ||
                    actionMenuCate !== 0 ||
                    actionMenhSim !== 0 ||
                    actionNetWork !== '' ||
                    thuebao !== '' ||
                    actionRangePriceSim !== '' ||
                    actionTranhso.length > 0) && (
                    <div className={cx('block-list-filter')}>
                      <label className={cx('block-list-filter_title')}>Đang tìm kiếm theo tiêu chí:</label>
                      <div className={cx('box-list-subcate')}>
                        <div className={cx('list-subcate')}>
                          <Stack direction="row" spacing={1}>
                            <ButtonUI
                              startIcon={<CancelIcon />}
                              bgrColorBtn={bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26'}
                              colorText={bgrDF.bgr ? bgrDF.bgr : '#cf000e'}
                              borderBtn={bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a'}
                              onClick={() => {
                                setActionMenhSim(0);
                                setActionMenuCate(0);
                                setActionNetWork('');
                                setTextSearch('');
                                setActionRangePriceSim('');
                                setActionTranhso([]);
                                setSearchDauSim('');
                                setTextSearch('');
                                setTextSearchChange('');
                                setCheckCoppy({});
                                setDataImageExcel([]);
                                fetchApiLocSim('', '', 0, '', 0, actionBtnSort, '', [], '');
                              }}
                            >
                              Xóa tất cả
                            </ButtonUI>
                            {textSearch !== '' && (
                              <ButtonUI
                                startIcon={<CancelIcon />}
                                bgrColorBtn={bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26'}
                                colorText={bgrDF.bgr ? bgrDF.bgr : '#cf000e'}
                                borderBtn={bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a'}
                                onClick={() => {
                                  setSearchDauSim('');
                                  setTextSearch('');
                                  setTextSearchChange('');
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                  fetchApiLocSim(
                                    '',
                                    actionNetWork,
                                    actionMenuCate,
                                    actionFormatSim,
                                    actionMenhSim,
                                    actionBtnSort,
                                    actionRangePriceSim,
                                    actionTranhso.join(''),
                                    thuebao,
                                  );
                                }}
                              >
                                {`Tìm sim:` + textSearch}
                              </ButtonUI>
                            )}
                            {actionMenuCate !== 0 && (
                              <ButtonUI
                                startIcon={<CancelIcon />}
                                bgrColorBtn={bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26'}
                                colorText={bgrDF.bgr ? bgrDF.bgr : '#cf000e'}
                                borderBtn={bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a'}
                                onClick={() => {
                                  setActionMenuCate(0);
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                  fetchApiLocSim(
                                    textSearch,
                                    actionNetWork,
                                    0,
                                    actionFormatSim,
                                    actionMenhSim,
                                    actionBtnSort,
                                    actionRangePriceSim,
                                    actionTranhso.join(''),
                                    thuebao,
                                  );
                                }}
                              >
                                {menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                                  ? menuSimResult.filter((x) => x.link === actionMenuCate)[0].title
                                  : ``}
                              </ButtonUI>
                            )}
                            {actionMenhSim !== 0 && (
                              <ButtonUI
                                startIcon={<CancelIcon />}
                                bgrColorBtn={bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26'}
                                colorText={bgrDF.bgr ? bgrDF.bgr : '#cf000e'}
                                borderBtn={bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a'}
                                onClick={() => {
                                  setActionMenhSim(0);
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                  fetchApiLocSim(
                                    textSearch,
                                    actionNetWork,
                                    actionMenuCate,
                                    actionFormatSim,
                                    0,
                                    actionBtnSort,
                                    actionRangePriceSim,
                                    actionTranhso.join(''),
                                    thuebao,
                                  );
                                }}
                              >
                                {arrayMenhSim.filter((x) => x.link === actionMenhSim).length > 0
                                  ? arrayMenhSim.filter((x) => x.link === actionMenhSim)[0].title
                                  : ``}
                              </ButtonUI>
                            )}
                            {actionNetWork !== '' && (
                              <ButtonUI
                                startIcon={<CancelIcon />}
                                bgrColorBtn={bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26'}
                                colorText={bgrDF.bgr ? bgrDF.bgr : '#cf000e'}
                                borderBtn={bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a'}
                                onClick={() => {
                                  setActionNetWork('');
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                  fetchApiLocSim(
                                    textSearch,
                                    '',
                                    actionMenuCate,
                                    actionFormatSim,
                                    actionMenhSim,
                                    actionBtnSort,
                                    actionRangePriceSim,
                                    actionTranhso.join(''),
                                    thuebao,
                                  );
                                }}
                              >
                                {actionNetWork}
                              </ButtonUI>
                            )}
                            {actionRangePriceSim !== '' && (
                              <ButtonUI
                                startIcon={<CancelIcon />}
                                bgrColorBtn={bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26'}
                                colorText={bgrDF.bgr ? bgrDF.bgr : '#cf000e'}
                                borderBtn={bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a'}
                                onClick={() => {
                                  setActionRangePriceSim('');
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                  fetchApiLocSim(
                                    textSearch,
                                    actionNetWork,
                                    actionMenuCate,
                                    actionFormatSim,
                                    actionMenhSim,
                                    actionBtnSort,
                                    '',
                                    actionTranhso.join(''),
                                    thuebao,
                                  );
                                }}
                              >
                                {actionRangePriceSim
                                  ? priceRange.find((price) => price.value === actionRangePriceSim)?.titlePrice ||
                                    numberFormatText(actionRangePrice.startPrice) +
                                      ' - ' +
                                      numberFormatText(actionRangePrice.endPrice)
                                  : 'Khoảng giá'}
                              </ButtonUI>
                            )}
                            {actionTranhso.length > 0 && (
                              <ButtonUI
                                startIcon={<CancelIcon />}
                                bgrColorBtn={bgrDF.bgr ? bgrDF.bgr + '26' : '#cf000e26'}
                                colorText={bgrDF.bgr ? bgrDF.bgr : '#cf000e'}
                                borderBtn={bgrDF.bgr ? bgrDF.bgr + '4a' : '#cf000e4a'}
                                onClick={() => {
                                  setActionTranhso([]);
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                  fetchApiLocSim(
                                    textSearch,
                                    actionNetWork,
                                    actionMenuCate,
                                    actionFormatSim,
                                    actionMenhSim,
                                    actionBtnSort,
                                    actionRangePriceSim,
                                    '',
                                    thuebao,
                                  );
                                }}
                              >
                                {`Tránh số: ${actionTranhso}`}
                              </ButtonUI>
                            )}
                            {thuebao !== '' && (
                              <ButtonUI
                                startIcon={<CancelIcon />}
                                bgrColorBtn={bgrDF.bgr + '26'}
                                colorText={bgrDF.bgr}
                                borderBtn={bgrDF.bgr + '4a'}
                                onClick={() => {
                                  setActionTranhso([]);
                                  setCheckCoppy({});
                                  setDataImageExcel([]);
                                  fetchApiLocSim(
                                    textSearch,
                                    actionNetWork,
                                    actionMenuCate,
                                    actionFormatSim,
                                    actionMenhSim,
                                    actionBtnSort,
                                    actionRangePriceSim,
                                    actionTranhso.join(''),
                                    '',
                                  );
                                  setThuebao('');
                                }}
                              >
                                {`Thuê bao: ${thuebao}`}
                              </ButtonUI>
                            )}
                          </Stack>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={cx('list-product')}>
                    <Stack direction="row" spacing={1}>
                      {isShowCopyLink && (
                        <CopyToClipboard text={isCopyLink} onCopy={() => setIsShowCopyLink(false)}>
                          <TextField
                            error
                            label="ấn sao chép link"
                            variant="outlined"
                            size="small"
                            value={isCopyLink}
                            disabled
                            fullWidth
                            sx={{ m: 1 }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <ContentCopyIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </CopyToClipboard>
                      )}
                    </Stack>
                  </div>
                </div>
              </div>
            </>
            {/* )} */}

            <div
              style={{
                marginLeft: 5,
                marginRight: 5,
                marginTop: -3,
                marginBottom: 10,
                background: '#fff',
                borderRadius: 10,
              }}
            >
              <div className={cx('box-list-subcate-menu', pos && 'show-active-menu', 'dis-center-1')}>
                <div className={cx('list-subcate')}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      p: 0.5,
                    }}
                  >
                    <Debt dataSim={checkCoppy} bgrDF={bgrDF.bgr} />
                    {/* {(checkTokenDashboad('1') ||checkTokenDashboad('2') || checkTokenDashboad('3')) && ( */}
                    <Paper elevation={0} onClick={() => setOpenImagePhoi(true)}>
                      <Card
                        variant="outlined"
                        sx={{ borderRadius: 2, background: '#000d86', color: '#fff', cursor: 'pointer' }}
                      >
                        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                          {' '}
                          <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                            <h3 className={cx('text-nowrap')}>LÀM ẢNH SIM (PHÔI)</h3>
                          </Stack>
                          <Stack direction="column" spacing={0}>
                            <span className={cx('text-nowrap')}>
                              {Object.values(checkCoppy).length > 0
                                ? `Làm ảnh với ${Object.values(checkCoppy).length} sim`
                                : 'Chọn sim -> làm ảnh'}
                            </span>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                    {/* )} */}
                    <Paper
                      elevation={0}
                      onClick={() => {
                        setOpenImage(true);
                      }}
                    >
                      <Card
                        variant="outlined"
                        sx={{ borderRadius: 2, background: '#005d86', color: '#fff', cursor: 'pointer' }}
                      >
                        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                          {' '}
                          <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                            <h3 className={cx('text-nowrap')}>LÀM ẢNH EXCEL</h3>
                          </Stack>
                          <Stack direction="column" spacing={0}>
                            <span className={cx('text-nowrap')}>
                              {Object.values(checkCoppy).length > 0
                                ? `Làm ảnh với ${Object.values(checkCoppy).length} sim`
                                : 'Chọn sim -> làm ảnh'}
                            </span>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Paper>
                    <ExportExcel dataSim={checkCoppy} discount={resultDiscount} cateSim={menuSimResult} />
                    {(getToken?.permission === '2' || getToken?.permission === '3') && (
                      <ExportExcelToCate dataSim={checkCoppy} discount={resultDiscount} cateSim={menuSimResult} />
                    )}
                  </Stack>
                </div>
              </div>
            </div>

            {(getToken?.permission === '5' || getToken?.permission === '3') && (
              <Grid item md={12} xs={12} sx={{ mt: 1 }}>
                <div className={cx('detail-item-note-height')}>
                  {/* <div className={cx('detail-item-note')}>- Phí thuê giao dịch hộ giao tận nơi là </div> */}
                  <div className={cx('detail-item-note-gdh')}>
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                      <h3 className={cx('color-red')}>CÁCH THỨC GIAO SIM CHO KHÁCH</h3>
                      <ButtonUI
                        bgrColorBtn={'#3c00ff26'}
                        colorText={'#0035ff'}
                        borderBtn={'#0014ff4a'}
                        small
                        onClick={() => setIsShowGDH(!isShowGDH)}
                      >
                        <b>{isShowGDH ? 'ĐÓNG LẠI' : 'XEM CHI TIẾT'}</b>
                      </ButtonUI>
                    </Stack>
                    {isShowGDH && (
                      <>
                        <span>
                          <b>Cách 1: THUÊ GIAO DỊCH HỘ</b>
                        </span>
                        <p className={cx('list-product-title-sx ')}>
                          Khi khách chốt sim anh em chỉ việc lên đơn khách ở bất kì đâu trên đất nước việt nam chỉ cần
                          lên đơn
                          <b> 30 phút</b> sau là giao dịch hộ đến khách.{' '}
                          <b className={cx('color-red')}>
                            Như vậy tỷ lệ bùng kèo cực kì thấp mà nhanh gọn ae có tiền ngay
                          </b>
                        </p>
                        <p className={cx('list-product-title-sx ')}>
                          <b>Phí thuê giao dịch hộ theo giá khách</b>
                        </p>
                        {tablePriceGDH.map((price, i) => (
                          <p key={i}>
                            <b>
                              <i>
                                sim từ {numberFormatText(price.priceStart.toString())} -{' '}
                                {numberFormatText(price.priceEnd.toString())} : phí{' '}
                                {numberFormatText(price.cafe.toString())}
                              </i>
                            </b>
                          </p>
                        ))}
                        <span>
                          <b>Cách 2: KHÔNG MẤT PHÍ</b>
                        </span>
                        <p className={cx('list-product-title-sx ')}>
                          Khi khách chốt sim xong bạn kêu khách chuyển khoản và ảnh căn cước đăng ký chính chủ. Sau đó
                          bạn bank cho mình, gửi hồ sơ đăng ký sim cho khách và mình sẽ làm chính chủ và gửi sim bưu
                          điện hoặc khi làm xong kêu khách ra nhà mạng của sim đó cấp là xong. (Không nhận ship bưu điện
                          và thu tiền: vì trường hợp này khách bùng kèo rất cao)
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            )}

            {resultDiscount.length > 0 && (
              <div ref={elementRef} className={cx('wrapper-content')}>
                <div className={cx('main-content')}>
                  <div>
                    <h1 className={cx('title-sim-new')}>
                      <div className={cx('dis-center')}>
                        <SimCardIcon />
                        {menuSimResult.filter((x) => x.link === actionMenuCate).length > 0
                          ? menuSimResult.filter((x) => x.link === actionMenuCate)[0].title
                          : `Sim Số Đẹp Mới Về`}
                      </div>
                      {getToken?.permission !== '5' && (
                        <b
                          className={cx('btn-discount')}
                          onClick={() => {
                            setOpenDiscount(true);
                          }}
                        >
                          Chỉnh % Chiết Khấu
                        </b>
                      )}
                    </h1>
                    <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                      <b className={cx('dis-center')} style={{ cursor: 'pointer' }}>
                        {!isloadingCoppyAll && (loadingTotalSim === '' || loadingTotalSim === 'Tổng Sim') ? (
                          <Checkbox
                            checked={Object.values(checkCoppy).length > 0 ? true : false}
                            onClick={handleCoppyAll}
                          />
                        ) : (
                          <CircularProgress className={cx('loading-custom-gold')} />
                        )}
                        {!isSimDaily && simInfinteResultCount?.totalRows > 0 && loadingTotalSim === ''
                          ? `Tổng: ${simInfinteResultCount?.totalRows} sim`
                          : loadingTotalSim}
                      </b>
                    </Stack>
                    {/* <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                  <label className={cx('list-product-title-Cate')}>{handleCategory()}</label>
                </Stack> */}
                    <Grid container justifyContent={'center'} spacing={1}>
                      {/* Theme Layout load */}
                      {loadingTheme && <ThemeLoad />}
                      {loadingTheme && <ThemeLoad />}
                      <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loadingTheme}
                        onClick={handleClose}
                      >
                        <CircularProgress color="inherit" />
                        <b className={cx('loadingsim')}>Hệ thống đang tải dữ liệu sim ...</b>
                      </Backdrop>

                      <Grid container sx={{ p: 1 }} spacing={1}>
                        {/* <div style={{ marginTop: 5, display: 'flex', justifyContent: 'center' }}> */}
                        {simInfinteResult?.data?.length > 0 &&
                          simInfinteResult &&
                          simInfinteResult?.data?.map(
                            (sim, index) =>
                              sim.sim && (
                                <Grid key={index} item xs={12} md={6}>
                                  {/* {layoutSim === '1' && ( */}
                                  <Paper elevation={0}>
                                    <Card variant="outlined" sx={{ borderRadius: 3 }}>
                                      <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                                        <div className={cx('detail-item-price')}>
                                          {/* <Stack direction="row" alignItems="center"> */}

                                          <Stack direction="column" justifyContent="flex-end" sx={{ p: '2px' }}>
                                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                              <span
                                                className={cx(
                                                  sim.nhamang === ('Mobifone' || 'mobifone')
                                                    ? 'title-sim-df'
                                                    : sim.nhamang === ('Vinaphone' || 'vinaphone')
                                                    ? 'title-sim-blue'
                                                    : sim.nhamang === ('Itelecom' || 'itelecom' || 'Itel' || 'itel')
                                                    ? 'title-sim-red'
                                                    : sim.nhamang === ('Vietnamobile' || 'vietnamobile' || 'vnmb')
                                                    ? 'title-sim-gold'
                                                    : 'title-sim-red',
                                                  'dis-center',
                                                )}
                                              >
                                                {!checkCoppy[index] && (
                                                  <CheckBoxOutlineBlankIcon
                                                    sx={{ mr: 1, cursor: 'pointer' }}
                                                    color="action"
                                                    onClick={() => handleCoppy(index, sim)}
                                                  />
                                                )}
                                                {checkCoppy[index] && (
                                                  <CheckBoxIcon
                                                    sx={{ mr: 1, cursor: 'pointer' }}
                                                    color="action"
                                                    onClick={() => handleDeleteCoppy(index)}
                                                  />
                                                )}
                                                <b> {sim.sim_show}</b>
                                              </span>

                                              <Stack direction={'row'} spacing={1} alignItems="center">
                                                <b>{sim.thuebao && sim.thuebao + ' | '}</b>
                                                <b style={{ fontSize: '16px' }}>
                                                  {/* <b className={cx('price-old-item')}>
                                                    {sim.price_old && numberFormatText(sim.price_old)}
                                                  </b> */}
                                                  {sim.price && numberFormat(sim.price)}
                                                </b>
                                              </Stack>
                                            </Stack>
                                            <Stack
                                              direction="row"
                                              spacing={0.5}
                                              justifyContent="space-between"
                                              alignItems="center"
                                            >
                                              {(getToken?.permission === '3' ||
                                                getToken?.permission === '7' ||
                                                getToken?.permission === '5') && (
                                                <b style={{ marginLeft: '35px' }}>
                                                  {sim.nhamang && sim.nhamang}{' '}
                                                  <span
                                                    style={{
                                                      padding: 1,
                                                      background: 'red',
                                                      color: '#fff',
                                                      borderRadius: 5,
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={() => addKeepSim(sim.sim, sim.id)}
                                                  >
                                                    Giữ số
                                                  </span>
                                                </b>
                                              )}

                                              {/* {favourite(sim.simSearch) && <FavoriteIcon sx={{ mr: 1 }} color="error" />} */}
                                              {/* <b>Giá:</b> */}
                                              <Stack direction={'row'} spacing={1} alignItems="center">
                                                <b style={{ color: 'red', fontSize: '16px' }}>
                                                  {resultDiscount.length > 0 &&
                                                    sim.price &&
                                                    sim.price_old &&
                                                    totalPriceDiscount(sim, resultDiscount)[0]}
                                                </b>
                                              </Stack>
                                            </Stack>
                                            <Stack
                                              direction="row"
                                              spacing={0.5}
                                              justifyContent="end"
                                              alignItems="center"
                                            >
                                              <b>{sim?.id_catesim && sim?.id_catesim}</b>
                                            </Stack>

                                            <Stack
                                              direction="row"
                                              spacing={0.5}
                                              justifyContent="end"
                                              alignItems="center"
                                            >
                                              <b style={{ color: 'blue' }}>{sim?.note_sim && sim?.note_sim}</b>
                                            </Stack>
                                          </Stack>
                                          {/* </Stack> */}
                                        </div>
                                      </CardContent>
                                    </Card>
                                  </Paper>
                                  {/* )} */}
                                </Grid>
                              ),
                          )}
                        {/* </div> */}
                      </Grid>
                    </Grid>
                    {!loadinglist &&
                      !isSimDaily &&
                      simInfinteResult &&
                      simInfinteResult.data.length !== 0 &&
                      simInfinteResult.data.length <= 10 &&
                      getCookie('token')?.is_simdaily > 0 && (
                        <div className={cx('xem-them-sim')}>
                          <ButtonUI
                            startIcon={<SimCardRoundedIcon />}
                            bgrColorBtn={bgrDF.bgr ? bgrDF.bgr + '26' : '#F3F4F6'}
                            colorText={bgrDF.bgr ? bgrDF.bgr : '#444444'}
                            borderBtn={bgrDF.bgr ? bgrDF.bgr + '4a' : '#44444447'}
                            onClick={() => {
                              searchSimDaily(
                                textSearch,
                                actionNetWork,
                                '',
                                actionRangePriceSim,
                                actionBtnSort,
                                simInfinteResult,
                                1,
                              );
                            }}
                          >
                            XEM THÊM SIM
                          </ButtonUI>
                        </div>
                      )}
                    <div ref={loader}>
                      {loadinglist && (
                        <div className={cx('css-load-sim')}>
                          <CircularProgress color="inherit" size={20} />
                          <b className={cx('pl-1')}>Tải dữ liệu...</b>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* COPY XUẤT ẢNH EXCEL  */}
                <Dialog
                  fullScreen
                  open={openImage}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <AppBar sx={{ position: 'relative', background: `var(--home-color)` }}>
                    <Toolbar>
                      <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                      </IconButton>
                      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        XUẤT ẢNH SIM SỐ DẠNG BẢNG
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>
                    <DialogContentText>
                      {/* {checkTokenDashboad('3') && (
                      <Box sx={{ mt: 1 }}>
                        <TextField
                          label="Text in ảnh"
                          size="small"
                          variant="outlined"
                          multiline
                          rows={4}
                          value={textCopiedSim}
                          color="error"
                          onChange={(e) => {
                            setTextCopiedSim(e.target.value);
                          }}
                          focused
                        />
                      </Box>
                    )} */}
                      <Box sx={{ mt: 1 }}>
                        <TextField
                          label="TIÊU ĐỀ ẢNH"
                          size="small"
                          variant="outlined"
                          value={isCheckInFoIMG?.title}
                          color="error"
                          onChange={(e) => {
                            handleIsInFoImage({ title: e.target.value });
                          }}
                          fullWidth
                          focused
                        />
                      </Box>

                      <Box sx={{ mt: 1.5 }}>
                        <TextField
                          label="TIÊU ĐỀ CUỐI ẢNH"
                          size="small"
                          variant="outlined"
                          value={isCheckInFoIMG?.title_footer}
                          color="error"
                          onChange={(e) => {
                            handleIsInFoImage({ title_footer: e.target.value });
                          }}
                          fullWidth
                          focused
                        />
                      </Box>
                      {getToken?.permission === '3' && (
                        <Box sx={{ mt: 1.5 }}>
                          <TextField
                            label="TEXT CHỈNH SỬA"
                            size="small"
                            variant="outlined"
                            value={textCopiedSimAndPrice}
                            color="error"
                            multiline
                            rows={4}
                            onChange={(e) => {
                              setTextCopiedSimAndPrice(e.target.value);
                              convertTextToArray(e.target.value);
                            }}
                            fullWidth
                            focused
                          />
                          <Box sx={{ mt: 1.5 }}>
                            <Stack direction={'row'} spacing={1}>
                              <TextField
                                label="TIÊU CỘT A"
                                size="small"
                                variant="outlined"
                                value={isCheckInFoIMG?.title_cols_a}
                                color="error"
                                onChange={(e) => {
                                  handleIsInFoImage({ title_cols_a: e.target.value });
                                }}
                                fullWidth
                                focused
                              />
                              <TextField
                                label="TIÊU CỘT B"
                                size="small"
                                variant="outlined"
                                value={isCheckInFoIMG?.title_cols_b}
                                color="error"
                                onChange={(e) => {
                                  handleIsInFoImage({ title_cols_b: e.target.value });
                                }}
                                fullWidth
                                focused
                              />
                            </Stack>
                          </Box>
                        </Box>
                      )}

                      <b>Số Cột trong 1 ảnh:</b>
                      <Grid container spacing={1} sx={{ mb: 1 }}>
                        {columnText.map((col, i) => (
                          <Grid xs={3} md={0.8} item key={i}>
                            <Button
                              key={i}
                              variant={isCheckInFoIMG.column === col ? 'contained' : 'outlined'}
                              size="small"
                              color="warning"
                              onClick={() => handleIsInFoImage({ column: col })}
                            >
                              {col}
                            </Button>
                          </Grid>
                        ))}
                        <b className={cx('dis-center')}>
                          <Checkbox
                            checked={isCheckInFoIMG.isPrice}
                            onClick={() => handleIsInFoImage({ isPrice: !isCheckInFoIMG.isPrice })}
                          />
                          Ẩn cột giá
                        </b>
                      </Grid>
                      <b>Số Lượng Text trong 1 ảnh:</b>
                      <Grid container spacing={1} sx={{ mb: 1 }}>
                        {soluongText.map((sl, i) => (
                          <Grid xs={3} md={0.8} item key={i}>
                            <Button
                              variant={isCheckInFoIMG.soluong === sl ? 'contained' : 'outlined'}
                              size="small"
                              color="warning"
                              onClick={() => handleIsInFoImage({ soluong: sl })}
                            >
                              {sl}
                            </Button>
                          </Grid>
                        ))}
                        <TextField
                          label="Số lượng tùy chỉnh"
                          size="small"
                          variant="outlined"
                          value={isCheckInFoIMG?.soluong}
                          sx={{ mt: 1 }}
                          color="error"
                          onChange={(e) => {
                            handleIsInFoImage({ soluong: e.target.value });
                          }}
                          focused
                        />
                      </Grid>
                      <b>Chọn màu ảnh:</b>
                      <Grid container spacing={1} sx={{ mb: 2.5 }}>
                        {colorTextImage.map((cl, i) => (
                          <Grid xs={3} md={0.8} item key={i}>
                            <Button
                              variant="contained"
                              size="small"
                              color="warning"
                              onClick={() => handleIsInFoImage({ color: cl.name })}
                              sx={{ background: cl.color }}
                            >
                              <CheckCircleIcon
                                sx={{ color: isCheckInFoIMG.color === cl.name ? '#ffeb3b' : '#ffffff' }}
                              />
                            </Button>
                          </Grid>
                        ))}
                      </Grid>

                      <Grid container spacing={1}>
                        <ImageExcel
                          dataSim={dataImageExcel}
                          isPrice={isCheckInFoIMG.isPrice}
                          column={isCheckInFoIMG.column}
                          soluong={isCheckInFoIMG.soluong}
                          color={isCheckInFoIMG.color}
                          title={isCheckInFoIMG.title}
                          titleFooter={isCheckInFoIMG.title_footer}
                          titleColsA={isCheckInFoIMG.title_cols_a}
                          titleColsB={isCheckInFoIMG.title_cols_b}
                        />
                      </Grid>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>ĐÓNG</Button>
                  </DialogActions>
                </Dialog>
                {/* ẢNH PHÔI SIM GỐC  */}
                <Dialog
                  fullScreen
                  open={openImagePhoi}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <AppBar sx={{ position: 'relative', background: `var(--home-color)` }}>
                    <Toolbar>
                      <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                      </IconButton>
                      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        XUẤT ẢNH PHÔI SIM SỐ
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <DialogContent sx={{ p: 0 }}>
                    <DialogContentText>
                      <Photo
                        sim={textCopiedSimPhoi.replace(/\n/g, ',')}
                        price={textCopiedSimPhoiPrice.replace(/\n/g, ',')}
                        isQl={false}
                      />
                    </DialogContentText>
                  </DialogContent>
                  {/* <DialogActions>
                    <Button onClick={handleClose}>ĐÓNG</Button>
                  </DialogActions> */}
                </Dialog>

                {isScroll && (
                  <div>
                    {/* {(checkTokenDashboad('2') || checkTokenDashboad('3')) && (
                      <ButtonUI
                        borderBtn="#f9a6a6"
                        colorText="#fff"
                        bgrColorBtn="#00524e"
                        style={{
                          position: 'fixed',
                          bottom: 240,
                          left: 10,
                          display: Object.values(checkCoppy).length > 0 ? 'flex' : 'none',
                          zIndex: 10,
                          borderRadius: '10px',
                        }}
                        onClick={() => setOpenImagePhoi(true)}
                      >
                        <b style={{ lineHeight: '1.5' }}>ẢNH PHÔI SIM</b>
                      </ButtonUI>
                    )}
                    <ButtonUI
                      borderBtn="#f9a6a6"
                      colorText="#fff"
                      bgrColorBtn="#002578"
                      style={{
                        position: 'fixed',
                        bottom: 200,
                        left: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        setOpenImage(true);
                      }}
                    >
                      <b style={{ lineHeight: '1.5' }}>ẢNH EXCEL</b>
                    </ButtonUI> */}
                    {/* <Box
                      style={{
                        position: 'fixed',
                        bottom: 160,
                        left: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                        borderRadius: '10px',
                      }}
                    >
                      <ExportExcel dataSim={checkCoppy} discount={resultDiscount} cateSim={menuSimResult} />
                    </Box> */}
                    {/* {(checkTokenDashboad('2') || checkTokenDashboad('3')) && (
                      <Box
                        style={{
                          position: 'fixed',
                          bottom: 120,
                          left: 10,
                          display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                          zIndex: 10,
                          borderRadius: '10px',
                        }}
                      >
                        <ExportExcelToCate dataSim={checkCoppy} discount={resultDiscount} cateSim={menuSimResult} />
                      </Box>
                    )} */}

                    <Box
                      style={{
                        position: 'fixed',
                        bottom: 440,
                        right: 10,
                        display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                        zIndex: 10,
                      }}
                    >
                      <TextField
                        label="Thêm icon:"
                        size="small"
                        sx={{ width: '100px', background: '#fff' }}
                        variant="outlined"
                        value={iconCopy}
                        color="error"
                        onChange={(e) => {
                          setIconCopy(e.target.value);
                          handleCoppyIcon(e);
                        }}
                        onClick={() => handleIsFocus(true)}
                        // onBlur={() => handleIsFocus(false)}
                        focused
                      />
                    </Box>

                    <CopyToClipboard text={textCopiedSimAndPriceGiaThuKhach} onCopy={() => setIsCopied(true)}>
                      <ButtonUI
                        borderBtn="#bef5ff"
                        colorText="#fff"
                        bgrColorBtn="#000000"
                        style={{
                          position: 'fixed',
                          bottom: 400,
                          right: 10,
                          display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                          zIndex: 10,
                        }}
                        onClick={submitBtnCopied}
                      >
                        Copy {Object.values(checkCoppy).length} sim và giá Khách (%CK)
                      </ButtonUI>
                    </CopyToClipboard>

                    <CopyToClipboard text={textCopiedSimAndPriceGiaThu} onCopy={() => setIsCopied(true)}>
                      <ButtonUI
                        borderBtn="#bef5ff"
                        colorText="#fff"
                        bgrColorBtn="#000000"
                        style={{
                          position: 'fixed',
                          bottom: 360,
                          right: 10,
                          display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                          zIndex: 10,
                        }}
                        onClick={submitBtnCopied}
                      >
                        Copy {Object.values(checkCoppy).length} sim và giá thu
                      </ButtonUI>
                    </CopyToClipboard>

                    <CopyToClipboard text={textCopiedSimAndPriceGiaThuKoTach} onCopy={() => setIsCopied(true)}>
                      <ButtonUI
                        borderBtn="#bef5ff"
                        colorText="#fff"
                        bgrColorBtn="#000000"
                        style={{
                          position: 'fixed',
                          bottom: 320,
                          right: 10,
                          display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                          zIndex: 10,
                        }}
                        onClick={submitBtnCopied}
                      >
                        Copy {Object.values(checkCoppy).length} sim và giá thu (Số Ko Tách)
                      </ButtonUI>
                    </CopyToClipboard>

                    <CopyToClipboard text={textCopiedSimAndPrice} onCopy={() => setIsCopied(true)}>
                      <ButtonUI
                        borderBtn="#bef5ff"
                        color="info"
                        style={{
                          position: 'fixed',
                          bottom: 280,
                          right: 10,
                          display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                          zIndex: 10,
                        }}
                        onClick={submitBtnCopied}
                      >
                        Copy {Object.values(checkCoppy).length} sim và giá
                      </ButtonUI>
                    </CopyToClipboard>
                    <CopyToClipboard text={textCopied} onCopy={() => setIsCopied(true)}>
                      <ButtonUI
                        borderBtn="#bef5ff"
                        color="info"
                        style={{
                          position: 'fixed',
                          bottom: 240,
                          right: 10,
                          display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                          zIndex: 10,
                        }}
                        onClick={submitBtnCopied}
                      >
                        Copy {Object.values(checkCoppy).length} sim chi tiết (%CK)
                      </ButtonUI>
                    </CopyToClipboard>
                    <CopyToClipboard text={textCopiedSim} onCopy={() => setIsCopied(true)}>
                      <ButtonUI
                        borderBtn="#bef5ff"
                        color="info"
                        style={{
                          position: 'fixed',
                          bottom: 200,
                          right: 10,
                          display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                          zIndex: 10,
                        }}
                        onClick={submitBtnCopied}
                      >
                        Copy {Object.values(checkCoppy).length} sim
                      </ButtonUI>
                    </CopyToClipboard>
                    <CopyToClipboard text={textCopiedChot} onCopy={() => setIsCopied(true)}>
                      <ButtonUI
                        borderBtn="#d1fae5"
                        color="success"
                        style={{
                          position: 'fixed',
                          bottom: 160,
                          right: 10,
                          display: Object.values(checkCoppy).length > 0 ? 'block' : 'none',
                          zIndex: 10,
                        }}
                        onClick={submitBtnCopied}
                      >
                        Mẫu đặt sim
                      </ButtonUI>
                    </CopyToClipboard>
                    <ButtonUI
                      borderBtn="#f9a6a6"
                      colorText="#fff"
                      bgrColorBtn="#e10000"
                      style={{
                        position: 'fixed',
                        bottom: 70,
                        right: 10,
                        display: pos ? 'flex' : 'none',
                        zIndex: 10,
                      }}
                      onClick={handleTop}
                    >
                      <ArrowCircleUpSharpIcon size="medium" />
                    </ButtonUI>

                    {authDomain?.background && (
                      <ButtonUI
                        colorText="#fff"
                        borderBtn="gold"
                        style={{
                          position: 'fixed',
                          bottom: pos ? 105 : 80,
                          right: 10,
                          display: 'flex',
                          zIndex: 10,
                          borderRadius: '20px',
                          background: `var(--home-color)`,
                        }}
                        onClick={() => {
                          setOpenShare(true);
                        }}
                      >
                        <ShareIcon
                          size="medium"
                          sx={{ border: '2px solid gold', color: 'gold', borderRadius: 10, p: 0.5, mr: 0.5 }}
                        />
                        <b style={{ lineHeight: '1.5' }}>
                          chia sẻ <br /> BẠN BÈ
                        </b>
                      </ButtonUI>
                    )}
                  </div>
                )}
              </div>
            )}
            <Dialog open={openShare} onClose={handleClose}>
              <DialogTitle>{'Chia sẻ link (ấn vào để copy):'}</DialogTitle>
              <DialogContent>
                <TextField
                  label="ấn vào để copy"
                  sx={{ m: 1 }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{`Link:`}</InputAdornment>,
                  }}
                  size="small"
                  value={window.location.href.replace('/?', '?')}
                  error
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    // alert('Đã copy link hãy gửi cho bạn bè!');
                    handleClose();
                  }}
                />
              </DialogContent>
            </Dialog>

            <Snackbar open={openSnac} autoHideDuration={6000} onClose={() => setOpenSnac(false)}>
              <Alert
                onClose={() => setOpenSnac(false)}
                severity={errorSnac.isError ? 'error' : 'success'}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {errorSnac.message}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default HomeCtv;
