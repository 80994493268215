import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { numberFormatDeleteDots } from '../../../utils/helper';
import { logicFormatSim } from './logicFormatSim';

async function readExcelFile(file) {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);

  const worksheet = workbook.worksheets[0];
  const rows = [];

  worksheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
    // row.eachCell({ includeEmpty: true }, (cell, cellIndex) => {
    //   const phone = `${numberFormatDeleteDots(cell.value)}`;
    //   console.log('Cell ' + rowIndex + ' = ' + cell.value);
    //   // const checkFormatSim = logicFormatSim(phone);
    //   // const textSim = `${phone} - ${checkFormatSim}`;

    //   rows.push(cell.value);
    // });
    const rowData = row.values;
    const sim = `${numberFormatDeleteDots(rowData[1])}`;
    const sim_show = rowData[2] ? rowData[2] : '';
    const price = rowData[3] ? rowData[3] : '';
    const mang = rowData[4] ? rowData[4] : '';
    const thuebao = rowData[5] ? rowData[5] : '';
    const objsim = [sim, sim_show, price, mang, thuebao];
    rows.push(objsim);
  });

  return rows;
}

function Excel() {
  const [dataExcel, setDataExcel] = useState([]);
  const [numberDefault, setNumberDefault] = useState([]);
  const [dataXoaSo, setDataXoaSo] = useState([]);

  function mixPairs() {
    const arr = [
      '13',
      '31',
      '14',
      '41',
      '19',
      '91',
      '26',
      '62',
      '27',
      '72',
      '28',
      '82',
      '34',
      '43',
      '39',
      '93',
      '49',
      '94',
      '67',
      '76',
      '68',
      '86',
      '78',
      '87',
    ];
    let usedPairs = new Set();
    let pairList = [];
    let allPossiblePairs = [];

    // Tạo tất cả các cặp có thể, bao gồm cả chính nó
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        let pair = arr[i] + arr[j];
        allPossiblePairs.push(pair);
      }
    }

    // Xáo trộn danh sách cặp để random
    // allPossiblePairs = allPossiblePairs.sort(() => Math.random() - 0.5);

    // Lặp qua danh sách đã xáo trộn và thêm vào kết quả nếu chưa có
    for (let pair of allPossiblePairs) {
      if (!usedPairs.has(pair)) {
        usedPairs.add(pair);
        pairList.push(pair);
      }
    }
    // console.log(pairList);
    setNumberDefault(pairList);
  }

  useEffect(() => {
    mixPairs();
  }, []);

  function handleFileChange(e) {
    const file = e.target.files[0];

    readExcelFile(file)
      .then((data) => {
        let filteredData = data.slice();
        // Bước 1: Loại bỏ các số chứa "0" trong chuỗi
        // let filteredData = data.filter((num) => {
        //   return !num[0].includes('0');
        // });
        // Bước 2: Giữ lại chỉ một số khi có số cuối trùng nhau
        // let modifiedData = filteredData.map((num) => {
        //   const lastTwoDigits = num[0].slice(-2); // Lấy 2 số cuối
        //   if (lastTwoDigits[0] === lastTwoDigits[1]) {
        //     return num[0].slice(0, -1); // Loại bỏ 1 số cuối
        //   }
        //   return num;
        // });

        // Bước 3: Lọc các số có 4 số cuối nằm trong data2
        // filteredData = modifiedData.filter((num) => {
        //   return numberDefault.some((suffix) => num[0].endsWith(suffix));
        // });

        filteredData = filteredData.map((num) => {
          let original = data.find((orig) => {
            return orig[0].startsWith(num[0]);
          });
          // console.log(num[0], original);
          return ['0' + num[0], num[1], num[2], num[3], num[4]];
        });

        // Bước 5: Loại bỏ các chuỗi trùng lặp
        filteredData = [...new Set(filteredData)];

        // Bước 6: Xóa các số trong data3 khỏi danh sách
        if (dataXoaSo.length > 0) {
          filteredData = filteredData.filter((num) => !dataXoaSo.includes(num[0]));
        }
        setDataExcel(filteredData);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const exportToExcel = (data) => {
    // Tạo một danh sách các đối tượng con tương ứng với từng dòng trong mảng data
    const worksheetData = data.map((item) => {
      // console.log(data);
      // console.log(item);
      // const [phone, names] = item.split(' - ');
      // const [phone] = item;
      // const [dinhdang6, dinhdang4, dinhdang3, dinhdang8] = names.split(',');

      return { sim: item[0], 'sim tach': item[1], gia: item[2], mang: item[3], 'thue bao': item[4] };
    });

    // Tạo workbook và worksheet mới từ dữ liệu được chuyển đổi, sử dụng thư viện xlsx
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);

    // Thêm worksheet vào workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Chuyển đổi workbook thành dạng binary tương ứng với file Excel
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Tạo blob từ dữ liệu excel để tải xuống
    const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Sử dụng hàm saveAs từ thư viện file-saver để tải xuống tệp
    saveAs(blob, 'data.xlsx');
  };

  // Gọi hàm exportToExcel khi bạn muốn tải xuống
  const handleExport = () => {
    exportToExcel(dataExcel);
  };

  const convertTextToArray = (data) => {
    if (data) {
      const dataArray = data.split('\n');
      console.log(dataArray);
      setDataXoaSo(dataArray);
    } else {
      setDataXoaSo([]);
    }
  };
  return (
    <Container sx={{ pb: '65px' }}>
      <Grid container>
        <Grid item md={3} xs={12} sx={{ m: 1 }}>
          <Button onClick={handleExport} variant="contained" sx={{ mb: 2 }}>
            Tải xuống Excel
          </Button>
          <TextField label="File excel" size="small" type="file" onChange={handleFileChange} focused />
          <Box sx={{ mt: 1.5 }}>
            <TextField
              label="TEXT CHỈNH SỬA"
              size="small"
              variant="outlined"
              value={dataXoaSo}
              color="error"
              multiline
              rows={4}
              onChange={(e) => {
                convertTextToArray(e.target.value);
              }}
              fullWidth
              focused
            />
          </Box>
        </Grid>
        <Grid item md={12} xs={12} sx={{ m: 1 }}>
          {dataExcel.map((row, i) => (
            <p key={i}>{row[0]}</p>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Excel;
