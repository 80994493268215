import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import * as userIDService from '../../../services/apiUsersCtv';
import { Backdrop, Button, ButtonBase, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import Cookies from 'js-cookie';
import images from '../../../assets/images';
import {
  checkTokenDashboad,
  getCookie,
  getLocalStorage,
  removeLocalStorage,
  setCookie,
  setLocalStorage,
} from '../../../utils/helper';
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
function LoginCtv() {
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [errorLogin, setErrorLogin] = useState('');
  const [loadingLogin, setLoadingLogin] = useState(true);
  let inFifteenMinutes = new Date(new Date().getTime() + 60 * 60 * 24 * 3 * 1000);
  const navigate = useNavigate();

  const hostname = window.location.hostname;

  const handleSubmit = async () => {
    setLoadingLogin(true);
    const dataUser = {
      user: user,
      pass: pass,
      domain: hostname,
    };
    const checkLogin = await userIDService.checkLoginUsers(dataUser);
    // console.log(checkLogin['data']);
    if (checkLogin.totalRow > 0) {
      setLoadingLogin(false);
      Cookies.set('token_sv', checkLogin['token'], { expires: inFifteenMinutes });
      setCookie('exp_sv', inFifteenMinutes, { expires: inFifteenMinutes });
      setCookie('token', checkLogin['data'], { expires: inFifteenMinutes });
      setErrorLogin('');
      setUser('');
      setPass('');
      window.location.href = '/dashboard';
      // navigate('/dashboard', { replace: true });
    } else {
      setErrorLogin('Sai tài khoản hoặc mật khẩu đăng nhập');
      setLoadingLogin(false);
    }
  };

  useEffect(() => {
    document.title = 'Đăng Nhập';
    const token = getCookie('token');
    if (token) {
      window.location.href = '/dashboard';
    } else {
      navigate('/dangnhap', { replace: true });
      // removeLocalStorage('token');
      // removeLocalStorage('token_sv');
    }
    setLoadingLogin(false);
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(${images.banner_login})`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        backgroundColor: 'rgb(255, 255, 255)',
      }}
    >
      <Paper
        sx={{
          p: 2,
          margin: 'auto',
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: '#ffffffd4',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          borderRadius: 6,
        }}
      >
        <Grid container spacing={2}>
          <Grid item sx={{ margin: 'auto' }}>
            <ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src={images.logokhoso} sx={{ border: '5px solid gold', borderRadius: 10 }} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} md={12} sm container justifyContent={'center'}>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <TextField
                  value={user}
                  error
                  onChange={(e) => setUser(e.target.value)}
                  label="Tài khoản"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs>
                <TextField
                  value={pass}
                  error
                  onChange={(e) => setPass(e.target.value)}
                  type="password"
                  label="Mật khẩu"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Typography color="error" variant="h6" component="h2">
                  {errorLogin}
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  endIcon={<LoginIcon />}
                  fullWidth
                  disabled={loadingLogin}
                  onClick={handleSubmit}
                >
                  <b>Đăng Nhập</b>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingLogin}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Paper>{' '}
    </div>
  );
}

export default LoginCtv;
