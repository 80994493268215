import * as requestAPI from '../utils/requestAPI';
import * as reqAuth from '../utils/requestAuthServer';
export const addCtv = async (data) => {
  try {
    const res = await reqAuth.postSim(`/addctv`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateCtv = async (data) => {
  try {
    const res = await reqAuth.postSim(`/updatectv`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeCtv = async (data) => {
  try {
    const res = await reqAuth.postSim(`/deletectv`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllCtv = async (page = 1, size = 50, hostname) => {
  try {
    const res = await reqAuth.getSim(`/listallctv?page=${page}&size=${size}&domain=${hostname}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listIdCtv = async (id) => {
  try {
    const res = await reqAuth.getSim(`/listctvid?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllCtv = async (hostname) => {
  try {
    const res = await reqAuth.getSim(`/listallcount?domain=${hostname}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// check login
export const checkLoginUsers = async (data) => {
  try {
    const res = await requestAPI.postSim(`/dangnhap`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
// refresh token
export const refreshToken = async (data) => {
  try {
    const res = await reqAuth.postSim(`/refreshctv`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// giữ sim
export const addKeepSimSo = async (data) => {
  try {
    const res = await reqAuth.postSim(`/addkeep`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const updateKeepSim = async (data) => {
  try {
    const res = await reqAuth.postSim(`/updatekeep`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const removeKeepSim = async (data) => {
  try {
    const res = await reqAuth.postSim(`/deletekeep`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listAllKeepSim = async (page = 1, size = 50, hostname) => {
  try {
    const res = await reqAuth.getSim(`/listallkeep?page=${page}&size=${size}&domain=${hostname}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllKeepSim = async (hostname) => {
  try {
    const res = await reqAuth.getSim(`/listallcountkeep?domain=${hostname}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listAllKeepSimUser = async (page = 1, size = 50, hostname, user) => {
  try {
    const res = await reqAuth.getSim(`/listallkeepus?page=${page}&size=${size}&domain=${hostname}&u=${user}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listCountAllKeepSimUser = async (hostname, user) => {
  try {
    const res = await reqAuth.getSim(`/listallcountkeepus?domain=${hostname}&u=${user}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
