import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import {
  addCtv,
  listAllCtv,
  listAllKeepSim,
  listAllKeepSimUser,
  listCountAllCtv,
  listCountAllKeepSim,
  listCountAllKeepSimUser,
  listIdCtv,
  removeCtv,
  updateCtv,
  updateKeepSim,
} from '../../../services/apiUsersCtv';
import { useLocation, useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { getCookie } from '../../../utils/helper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Create } from '@mui/icons-material';
import { checkSimId, listSimID, updateSim } from '../../../services/listSim';

export default function DsLoadSim() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#005663',
      color: theme.palette.common.white,
      borderRadius: 1,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const auth = getCookie('token');
  const hostname = window.location.hostname;
  const [resultList, setResultList] = useState([]);
  const [arrDefault, setArrDefault] = useState({ name: '', username: '', email: '', pass_word: '' });
  const [isDisabled, setIsDisabled] = useState(false);
  const [time, setTime] = useState({ id: '', sim: '', timeend: '', dayend: '' });
  const [message, setMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [page, setPage] = useState(1);
  const [countDefault, setCountDefault] = useState(0);
  const [checkInfo, setCheckInfo] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const idCtv = urlParams.get('u');
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listAll = async (pages = 1) => {
    const resultAPI =
      auth?.permission === '5'
        ? await listAllKeepSimUser(pages, 1000, hostname, auth?.username)
        : await listAllKeepSim(pages, 1000, hostname);
    if (resultAPI?.length > 0) {
      setResultList(resultAPI);
      checkTimeNowUpdateSim(resultAPI);
      const count =
        auth?.permission === '5'
          ? await listCountAllKeepSimUser(hostname, auth?.username)
          : await listCountAllKeepSim(hostname);
      setCountDefault(count.totalRows);
    }
  };

  useEffect(() => {
    document.title = 'Danh sách sim đang load';
    listAll(1);
  }, []);

  const checkTimeNowUpdateSim = async (data) => {
    const today = new Date();

    // Extract day, month, and year
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    // Add leading zero to day and month if needed
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    // Format the date as dd/mm/yyyy
    const formattedDate = `${day}/${month}/${year}`;

    const time =
      formattedDate +
      'T' +
      new Date().toLocaleTimeString('vi-VN', {
        timeZone: 'Asia/Ho_Chi_Minh',
      });

    data.map(async (row) => {
      const givenTimeString = row.timeend;
      const parseDateString = (dateString) => {
        const [datePart, timePart] = dateString.split('T');
        const [day, month, year] = datePart.split('/').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds);
      };

      const givenTime = parseDateString(givenTimeString);
      if (today.getTime() > givenTime.getTime()) {
        const simId = await checkSimId(hostname, row.sim);
        // Save it!
        if (simId.data.length > 0) {
          const bodysim = {
            id: simId.data[0].id,
            data: { status: 1 },
          };
          await updateSim(bodysim);
          //
          const body = {
            id: row.id,
            data: { status: 2 },
          };
          const resultAPI = await updateKeepSim(body);

          if (resultAPI?.message === 'OK') {
            listAll(1);
          }
        }
      }
    });
  };

  useEffect(() => {
    getListID(idCtv);
  }, [idCtv]);

  const getListID = async (id) => {
    if (id) {
      const resultID = await listIdCtv(id);
      if (resultID.length > 0) {
        setCheckInfo(true);
        setArrDefault(resultID[0]);
      } else {
        setCheckInfo(false);
      }
    }
  };

  const changeValueAdmin = (jsonVL) => {
    setArrDefault({ ...arrDefault, ...jsonVL });
  };

  const subUpdateDefault = async () => {
    setIsDisabled(true);
    const body = {
      id: time.id,
      data: { timeend: time.dayend + 'T' + time.timeend + ':00' },
    };
    const resultAPI = await updateKeepSim(body);
    if (resultAPI.message === 'OK') {
      setIsDisabled(false);
      listAll(1);
      setMessage('Thành Công');
      setOpenAlert(true);
      setCheckInfo(false);
      handleClose();
    } else {
      setIsDisabled(false);
      setMessage('Thất Bại');
      setOpenAlert(true);
    }
  };

  const alertRemove = async (id, sim) => {
    if (window.confirm(`Bạn đã chắc chắn BỎ LOAD này?`)) {
      const simId = await checkSimId(hostname, sim);
      // Save it!
      if (simId.data.length > 0) {
        const bodysim = {
          id: simId.data[0].id,
          data: { status: 1 },
        };
        await updateSim(bodysim);
        //
        const body = {
          id: id,
          data: { status: 2 },
        };
        const resultAPI = await updateKeepSim(body);

        if (resultAPI?.message === 'OK') {
          listAll(1);
        }
      }
    } else {
      // Do nothing!
      console.log('Hủy yêu cầu');
    }
  };

  const nextPage = () => {
    const total = Math.ceil(countDefault / 50);
    if (total > page) {
      setPage(page + 1);
      listAll(page + 1);
    }
  };

  const prevPage = () => {
    const total = Math.ceil(countDefault / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listAll(page - 1);
    }
  };

  return (
    <Grid container sx={{ pb: '90px', background: '#fff', p: 2 }} spacing={2}>
      <Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Sim ${time.sim}`}</DialogTitle>
          <DialogContent>
            <Stack direction={'row'} spacing={1} sx={{ mt: 1 }}>
              <TextField
                id="outlined-1"
                type="time"
                value={time.timeend}
                label="Thời gian"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setTime({ ...time, timeend: e.target.value });
                }}
              />
              <TextField
                id="outlined-2"
                type="text"
                value={time.dayend}
                label="Ngày"
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setTime({ ...time, dayend: e.target.value });
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Đóng</Button>
            <Button onClick={subUpdateDefault} variant="contained" color="error">
              Lưu Lại
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
      <Grid item xs={12}>
        <label>Tổng sim load: {countDefault}</label>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">STT</StyledTableCell>
                <StyledTableCell align="center">SIM LOAD</StyledTableCell>
                <StyledTableCell align="center">THỜI GIAN BẮT ĐẦU</StyledTableCell>
                <StyledTableCell align="center">THỜI GIAN KẾT THÚC</StyledTableCell>
                <StyledTableCell align="center">CTV GIỮ</StyledTableCell>
                <StyledTableCell align="center">TÁC VỤ</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultList?.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell align="center">{i + 1}</StyledTableCell>
                  <StyledTableCell align="center">{row.sim}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.timestart?.split('T')[1]} - {row.timestart?.split('T')[0]}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Stack direction={'column'} spacing={1} alignItems={'center'}>
                      <Stack direction={'row'} spacing={1}>
                        {row.timeend?.split('T')[1]} - {row.timeend?.split('T')[0]}
                        {auth?.permission !== '5' && (
                          <Create
                            color="success"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleClickOpen();
                              setTime({
                                id: row.id,
                                timeend: row.timeend?.split('T')[1],
                                dayend: row.timeend?.split('T')[0],
                                sim: row.sim,
                              });
                            }}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.username}</StyledTableCell>
                  <StyledTableCell align="center">
                    {auth?.permission !== '5' && <Button onClick={() => alertRemove(row.id, row.sim)}>Bỏ Load</Button>}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
