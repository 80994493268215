const images = {
  logo: require('./logo8.png'),
  logoxs: require('./logo8.png'),
  logologin: require('./logologin.png'),
  logokhoso: require('./logonew.png'),
  logoloading: require('./logoloading.png'),
  logosuccess: require('./logosuccess.png'),
  iconZalo: require('./icon-zalo.png'),
  logo_mb: require('./logo-mobifone.svg').default,
  logo_vt: require('./logo-viettel.svg').default,
  logo_vn: require('./logo-vinaphone.svg').default,
  logo_vnmb: require('./logo-vnmb.svg').default,
  logo_itel: require('./itelecom_logo.png'),
  logo_wintel: require('./wintel_logo.png'),
  banner_login: require('./banner_login.jpg'),
  mobifone_small: require('./mobifone_small.svg').default,
  viettel_small: require('./viettel_small.svg').default,
  vinaphone_small: require('./vinaphone_small.svg').default,
  vnmb_small: require('./vnmb_small.svg').default,
  itel_small: require('./itel_small.svg').default,
  reddi_small: require('./reddi_small.svg').default,
  phoi_viettel: require('./phoi-viettel.png'),
  phoi_vina: require('./phoi-vina.png'),
  phoi_gmobile: require('./phoi-gmobile.png'),
  phoi_mobi: require('./phoi-mobi.png'),
  phoi_vnmb: require('./phoi-vnmb.png'),
  phoi_itel: require('./phoi-itel.png'),
  phoi_default: require('./phoi_default.png'),
  phoi_vina_1: require('./vinaphone_phoi.png'),
  messenger: require('./messenger.png'),
  img_similar_pack: require('./img-similar-pack.svg').default,
  bannerqcvina: require('./salevina.png'),
  bannerflashsale: require('./bannerflashsale.jpg'),
  menhsim: require('./menhsim.jpg'),
  flashsale: require('./flash-sale.png'),
  lock: require('./lock.png'),
  phoisimdep: require('./phoisimdep.png'),
  android: require('./android.png'),
  ios: require('./ios.png'),
  gd1_1: require('./giaodien/gd1-1.png'),
  gd1_2: require('./giaodien/gd1-2.png'),
  gd1_3: require('./giaodien/gd1-3.png'),
  gd2_4: require('./giaodien/gd2-4.png'),
  gd2_5: require('./giaodien/gd2-5.png'),
  gd2_6: require('./giaodien/gd2-6.png'),
  gd3_7: require('./giaodien/gd3-7.png'),
  gd2_8: require('./giaodien/gd2_8.png'),
  filemausim: require('./maufilesim.xlsx'),
};

export default images;
